import { LinkIcon, PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';

import CLocalizedText from '../../../components/CLocalizedText';
import ActionLinkButton from '../../../components/dashboardContent/ActionLinkButton';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import XDResponsiveGridWrapper from '../../../components/layout/XDResponsiveGridWrapper';
import { PageRoutes } from '../../../enums/enums';

export default function FactsheetManagementIndex() {
  return (
    <Page>
      <PageModule>
        <h1>
          <CLocalizedText dictKey={'pubFactsheetOutletIndexH1'} />
        </h1>
        <XDResponsiveGridWrapper>
          <ActionLinkButton label={'New Link'} to={PageRoutes.PUBLISHING_FS_NEW_LINK}>
            <PlusIcon />
          </ActionLinkButton>
          <ActionLinkButton label={'All links'} to={PageRoutes.PUBLISHING_FS_OVERVIEW_OUTLET}>
            <LinkIcon />
          </ActionLinkButton>
        </XDResponsiveGridWrapper>
      </PageModule>
    </Page>
  );
}

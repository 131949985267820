import { useAppSelector } from '../hooks/hooks';

/**
 * Use as a function to return Localized Text as string. This function strips the text from any HTML tags.
 * @param dictKey
 * @constructor
 */
export default function CLocalizedTextString(dictKey: string) {
  const { localeDict } = useAppSelector((state) => state.dashboard);
  const html = localeDict?.[dictKey] ?? `-- ${dictKey} --`;
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.innerText;
}

import { Swipe } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';

import HGeneric from '../helpers/HGeneric';
import { updateState } from '../helpers/helper';
import { useAppSelector } from '../hooks/hooks';
import './CHorizontalScrollContainer.css';
import CLocalizedText from './CLocalizedText';

interface Props {
  children: React.ReactNode;
  sidePadding?: React.ReactNode;
  extendTouchscreenScrollArea?: boolean;
  extendedScrollAreaBoundary?: boolean;
}

interface State {
  isOverflown?: boolean;
  isDragging?: boolean;
  scrollbarOffsetX?: number;
}

export default function CHorizontalScrollContainer(props: Props) {
  const [state, setState] = useState<State>({});
  const { isTouchscreen, dashboardOutletDimensions } = useAppSelector((state) => state.dashboard);
  const scrollBoxRef = useRef<HTMLDivElement>(null);
  const sidePaddingRef = useRef<HTMLDivElement>(null);
  const scrollContent = useRef<HTMLDivElement>(null);

  const computePaddingWidth = () => {
    return (
      (Math.max(dashboardOutletDimensions.w, 1100) - 1100) / 2 +
      (document.documentElement.clientWidth >= 640 ? 4 : 1) * HGeneric.getRemInPx()
    );
  };

  const computeColumnWidth = (): number => {
    return dashboardOutletDimensions.w - 2 * computePaddingWidth();
  };

  const sidePadding = (
    <>
      {props.sidePadding ?? (
        <div
          className={'flex-shrink-0'}
          style={{
            width: computePaddingWidth()
          }}
        />
      )}
    </>
  );

  const checkIfOverflown = () => {
    const scrollBoxDOM = scrollBoxRef.current;
    if (!scrollBoxDOM) return;
    updateState<State>({ isOverflown: scrollBoxDOM.scrollWidth > scrollBoxDOM.clientWidth }, state, setState);
  };

  const computeSidePaddingWidth = () => {
    return sidePaddingRef.current?.clientWidth ?? 1;
  };

  const computeContentScrollWidth = () => {
    return (scrollContent.current?.getBoundingClientRect().width ?? 1) - 2 * computeSidePaddingWidth();
  };

  const computeScrollbarWidth = () => {
    return dashboardOutletDimensions.w - 2 * computeSidePaddingWidth();
  };

  const computeScrollbarSliderWidth = () => {
    return (computeScrollbarWidth() / computeContentScrollWidth()) * computeScrollbarWidth();
  };

  const computeDummyToRealScrollRatio = () => {
    return computeScrollbarWidth() / computeContentScrollWidth();
  };

  const handleSelectStart = (ev: Event) => {
    if (state.isDragging) ev.preventDefault();
  };

  const handleMouseDown = () => {
    updateState<State>({ isDragging: true }, state, setState);
    document.body.style.cursor = 'grabbing';
  };

  const handleMouseUp = () => {
    updateState<State>({ isDragging: false }, state, setState);
    document.body.style.cursor = 'auto';
  };

  const handleMouseMove = (ev: MouseEvent) => {
    ev.preventDefault();
    const scrollbarOffsetX = Math.min(
      Math.max((state.scrollbarOffsetX ?? 0) + ev.movementX, 0),
      computeScrollbarWidth() - computeScrollbarSliderWidth()
    );
    scrollBoxRef.current?.scroll({ top: 0, left: scrollbarOffsetX / computeDummyToRealScrollRatio() });
    updateState<State>({ scrollbarOffsetX }, state, setState);
  };

  const updateSliderPosition = (deltaX: number) => {
    updateState<State>(
      {
        scrollbarOffsetX:
          Math.min(
            computeContentScrollWidth() - computeScrollbarWidth(),
            Math.max(0, (scrollBoxRef.current?.scrollLeft ?? 0) + deltaX)
          ) * computeDummyToRealScrollRatio()
      },
      state,
      setState
    );
  };

  const handleWheel = (ev: React.WheelEvent) => {
    if (!ev.deltaX) return;
    updateSliderPosition(ev.deltaX);
  };

  useEffect(() => {
    checkIfOverflown();
    updateSliderPosition(0);
  }, [dashboardOutletDimensions.w, props.children]);

  useEffect(() => {
    if (state.isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('selectstart', handleSelectStart);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('selectstart', handleSelectStart);
    };
  }, [state.isDragging, state.scrollbarOffsetX]);

  return (
    <div className={'relative flex flex-col gap-4'}>
      <div className={'overflow-x-scroll relative c-page-module-plot'} ref={scrollBoxRef} onWheel={handleWheel}>
        <div className={'flex gap-0 w-fit'} ref={scrollContent}>
          {<div ref={sidePaddingRef}>{sidePadding}</div>}
          <div style={{ minWidth: computeColumnWidth() }}>{props.children}</div>
          {sidePadding}
        </div>
        {isTouchscreen && state.isOverflown && props.extendTouchscreenScrollArea && <div className={'h-24'}></div>}
      </div>
      {state.isOverflown && !isTouchscreen && (
        <div
          className={'relative h-fit flex flex-col gap-4 text-center text-xs opacity-50'}
          style={{
            width: computeScrollbarWidth(),
            marginLeft: sidePaddingRef.current?.clientWidth ?? 0
          }}
        >
          <div
            className={'bg-gray-700 opacity-50 hover:opacity-100 active:opacity-100 left-0 rounded-full'}
            style={{
              width: computeScrollbarSliderWidth(),
              marginLeft: state.scrollbarOffsetX,
              height: '9px'
            }}
            onMouseDown={handleMouseDown}
          />
          <p>
            <CLocalizedText dictKey={'messageHoldShiftToScroll1'} inline />
            &nbsp;
            <span className={'py-0 px-1 border border-gray-500 rounded-md'}>&nbsp;Shift &#8679;</span>
            &nbsp;&nbsp;
            <CLocalizedText dictKey={'messageHoldShiftToScroll2'} inline />
          </p>
        </div>
      )}
      {isTouchscreen && state.isOverflown && props.extendTouchscreenScrollArea && (
        <div
          className={
            'absolute bottom-0 left-0 text-center pointer-events-none w-full text-sm opacity-50 whitespace-nowrap'
          }
        >
          {props.extendedScrollAreaBoundary !== false && <div className={'absolute w-full opacity-30'} />}
          <div className={'flex gap-3 justify-center items-center py-10 px-4 w-fit m-auto relative -mb-2'}>
            <Swipe />
            <span>
              <CLocalizedText dictKey={'messageSwipeToScroll'} />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

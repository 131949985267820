import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

import { type EUserRoles } from '../../enums/EUserRoles';
import { userHasRole } from '../../utils/utils';
import NotFoundModule from './NotFoundModule';

interface AdminRouteProps {
  children: React.ReactNode;
  requiredRoles: EUserRoles[];
}

export default function RoleRequiredRoute(props: AdminRouteProps) {
  const { keycloak } = useKeycloak();

  return (
    <>
      {!userHasRole(keycloak.tokenParsed, props.requiredRoles) && <NotFoundModule />}
      {userHasRole(keycloak.tokenParsed, props.requiredRoles) && props.children}
    </>
  );
}

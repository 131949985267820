import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch } from '../../hooks/hooks';
import { addBreadcrumbTextReplacement, purgeBreadcrumbTextReplacementDictForOutlets } from '../../store/appSlice';

interface props {
  slug: string;
  title: string;
}

/**
 * Wrapper component for outlets.
 * @param props
 * @constructor
 */
export default function XDOutlet(props: props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addBreadcrumbTextReplacement([props.slug, props.title]));
    return () => {
      dispatch(purgeBreadcrumbTextReplacementDictForOutlets());
    };
  }, []);

  return <Outlet />;
}

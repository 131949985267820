import { OpenInNew } from '@mui/icons-material';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';

import { type ITextFormats } from '../models/ITextFormats';

export default class HGeneric {
  static getRemInPx() {
    return parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  static formatString(
    textInput: string | number | undefined,
    conversion: { in: ITextFormats; out: ITextFormats } | undefined
  ) {
    if (textInput === null || !textInput) return <>&nbsp;</>;
    switch (conversion?.in) {
      case 'tzTimestamp':
        if (conversion?.out === 'localeDateString')
          return dayjs(textInput).isValid()
            ? new Date(textInput ?? 0).toLocaleDateString(document.documentElement.lang, {
                day: 'numeric',
                month: '2-digit',
                year: 'numeric'
              })
            : textInput;
        break;
      case 'boolean':
        if (conversion.out === 'booleanString') return textInput ? 'true' : 'false';
        break;
      case 'linkString':
        if (conversion.out === 'linkHrefBlank')
          return textInput ? (
            <Link to={textInput as string} target={'_blank'} rel="noreferrer">
              Link <OpenInNew />
            </Link>
          ) : (
            ''
          );
        break;
      default:
        return textInput;
    }
  }
}

import {
  BriefcaseIcon as BriefcaseIconOutline,
  InboxStackIcon as InboxStackIconOutline,
  NewspaperIcon as NewspaperIconOutline,
  PresentationChartLineIcon as PresentationChartLineIconOutline,
  SparklesIcon as SparklesIconOutline,
  UserGroupIcon as UserGroupIconOutline
} from '@heroicons/react/24/outline';
import {
  BriefcaseIcon,
  InboxStackIcon,
  NewspaperIcon,
  PresentationChartLineIcon,
  SparklesIcon,
  UserGroupIcon
} from '@heroicons/react/24/solid';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { EUserRoles } from '../../enums/EUserRoles';
import { PageRoutes } from '../../enums/enums';
import { useAppSelector } from '../../hooks/hooks';
import { userHasRole } from '../../utils/utils';
import CLocalizedText from '../CLocalizedText';
import CLocalizedTextString from '../CLocalizedTextString';
import SidebarHeader from './SidebarHeader';
import SidebarLinkButton from './SidebarLinkButton';

interface sidebarContentProps {
  sidebarIsExpanded: boolean;
}

export default function SidebarContents({ sidebarIsExpanded }: sidebarContentProps) {
  const path = useLocation().pathname.split('/');
  const { keycloak } = useKeycloak();
  const { factsheetId } = useAppSelector((state) => state.dashboard);

  return (
    <div className={'flex flex-col gap-2'}>
      {(factsheetId ?? keycloak.authenticated) && (
        <SidebarLinkButton
          to={PageRoutes.NEWS_OUTLET}
          label={'News'}
          isSelected={[...path][1] === PageRoutes.NEWS_OUTLET}
          icon={<NewspaperIconOutline />}
          iconSelected={<NewspaperIcon />}
        />
      )}
      {keycloak.authenticated && (
        <SidebarLinkButton
          to={PageRoutes.SINGLE_STOCK_OUTLET}
          label={'Single Stock'}
          isSelected={[...path][1] === PageRoutes.SINGLE_STOCK_OUTLET}
          icon={<PresentationChartLineIconOutline />}
          iconSelected={<PresentationChartLineIcon />}
        />
      )}
      {/* <SidebarLinkButton */}
      {/*  to={PageRoutes.BACKTEST_OUTLET} */}
      {/*  label={'Backtest'} */}
      {/*  isSelected={[...path][1] === PageRoutes.BACKTEST_OUTLET} */}
      {/*  icon={<DocumentChartBarIconOutline/>} */}
      {/*  iconSelected={<DocumentChartBarIcon/>} */}
      {/* /> */}
      <SidebarLinkButton
        to={`${PageRoutes.PUBLIC_FACTSHEET_COLLECTION_OUTLET}/${localStorage.getItem('factsheet-id') ?? ''}`}
        label={<CLocalizedText dictKey={'globalSectionFactsheet'} />}
        isSelected={[...path][1] === PageRoutes.PUBLIC_FACTSHEET_COLLECTION_OUTLET}
        icon={<SparklesIconOutline />}
        iconSelected={<SparklesIcon />}
      />
      {userHasRole(keycloak.tokenParsed, [EUserRoles.STAFF]) && (
        <>
          {sidebarIsExpanded && <div className={'h-2'} />}
          <SidebarHeader show={sidebarIsExpanded}>Admin Console</SidebarHeader>
          <SidebarLinkButton
            to={PageRoutes.OPS_NEWS_OUTLET}
            label={CLocalizedTextString('globalSidebarOpsNews')}
            isSelected={[...path][1] === PageRoutes.OPS_NEWS_OUTLET}
            icon={<BriefcaseIconOutline />}
            iconSelected={<BriefcaseIcon />}
          />
          <SidebarLinkButton
            to={PageRoutes.PUBLISHING_OUTLET}
            label={CLocalizedTextString('globalSidebarPublications')}
            isSelected={[...path][1] === PageRoutes.PUBLISHING_OUTLET}
            icon={<InboxStackIconOutline />}
            iconSelected={<InboxStackIcon />}
          />
          {userHasRole(keycloak.tokenParsed, [EUserRoles.ADMIN]) && (
            <SidebarLinkButton
              to={PageRoutes.USERS_OUTLET}
              label={'User Accounts'}
              isSelected={[...path][1] === PageRoutes.USERS_OUTLET}
              icon={<UserGroupIconOutline />}
              iconSelected={<UserGroupIcon />}
            />
          )}
        </>
      )}
    </div>
  );
}

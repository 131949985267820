import { Button } from '@mui/material';
import React from 'react';

interface ButtonBorderedProps {
  label?: React.ReactNode;
  icon?: JSX.Element;
  trailingIcon?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  growOnMobile?: boolean;
  sxColor?: string;
  sxColor80Pct?: string;
}

export default function PaddedButton({
  label,
  icon,
  trailingIcon,
  disabled,
  color,
  onClick,
  className,
  growOnMobile,
  sxColor,
  sxColor80Pct
}: ButtonBorderedProps) {
  return (
    <Button
      sx={{
        paddingX: label ? '1.5rem' : '1.2rem',
        paddingY: '0.75rem',
        minWidth: '0',
        color: sxColor ?? 'auto',
        borderColor: sxColor80Pct ?? 'auto',
        ':hover': {
          borderColor: sxColor ?? 'auto'
        }
      }}
      disabled={disabled}
      onClick={() => {
        onClick?.();
      }}
      color={color}
      variant={'outlined'}
      className={`${growOnMobile ? 'w-full sm:w-auto' : ''} flex-none ` + className}
    >
      <div className={'flex gap-1 items-center'}>
        {!trailingIcon && icon != null ? <span className={'text-lg -mt-1'}>{icon}</span> : null}
        {label && <span className={'py-[0.2rem] font-bold'}>{label}</span>}
        {trailingIcon && icon != null ? <span className={'text-lg -mt-1'}>{icon}</span> : null}
      </div>
    </Button>
  );
}

import { type AlertColor } from '@mui/material';
import React, { type CSSProperties, useEffect, useRef, useState } from 'react';

import { updateStateAttribute } from '../helpers/helper';
import { type IRequestState } from '../models/IRequestState';
import CAlert from './CAlert';
import CPageModule from './CPageModule';
import LoadWheel from './general/LoadWheel';

export interface StatefulContainerProps {
  apiRequestState?: IRequestState;
  errorMessage?: React.ReactNode;
  severity?: AlertColor;
  showContent?: boolean;
  containerPosition?: CSSProperties['position'];
  divRef?: React.RefObject<HTMLDivElement>;
  submodule?: true;
}

interface statefulContentContainerProps extends StatefulContainerProps {
  children?: React.ReactNode;
}

interface State {
  refRect?: DOMRect;
  loadWheelOffset: number;
  timeout?: NodeJS.Timeout;
}

/**
 * A placeholder module container to display error message if the API request
 * fails. In the future, should also include a load wheel. The container fills
 * the entire width of the parent container.
 * @param children
 * @param pageState
 * @param errorMessage
 * @constructor
 */
export default function CStatefulContainer({
  children,
  apiRequestState,
  errorMessage,
  severity,
  showContent,
  containerPosition,
  divRef,
  submodule
}: statefulContentContainerProps) {
  const [state, setState] = useState<State>({ loadWheelOffset: 0 });

  const ref = useRef<HTMLDivElement>(null);
  const errorWarning = (
    <>
      {typeof errorMessage !== 'object' && (
        <CAlert
          severity={severity ?? 'error'}
          // className={'transition-opacity ease-in-out duration-1000'}
          style={{ opacity: apiRequestState?.isLoading ? 0.5 : 1 }}
        >
          {errorMessage ?? apiRequestState?.strError}
        </CAlert>
      )}
      {typeof errorMessage === 'object' && errorMessage}
    </>
  );

  const updateLoadwheelOffset = () => {
    if (!ref.current) return;
    updateStateAttribute<State>(
      {
        timeout: setTimeout(() => {
          let loadWheelOffset = ((ref.current?.getBoundingClientRect().height ?? 0) - 90) / 2;
          if (window.innerHeight <= (ref.current?.getBoundingClientRect().height ?? 0)) {
            const oversizedOffset = 50;
            if (window.innerHeight / 2 <= (ref.current?.getBoundingClientRect().top ?? 0) + 45 + oversizedOffset) {
              loadWheelOffset = 45 + oversizedOffset;
            } else if (
              window.innerHeight / 2 >
              (ref.current?.getBoundingClientRect().bottom ?? 0) - 135 + oversizedOffset
            ) {
              loadWheelOffset = (ref.current?.getBoundingClientRect().height ?? 0) - 135 + oversizedOffset;
            } else {
              loadWheelOffset = window.innerHeight / 2 - (ref.current?.getBoundingClientRect().top ?? 0);
            }
          }
          updateStateAttribute<State>({ refRect: ref.current?.getBoundingClientRect(), loadWheelOffset }, setState);
        }, 0)
      },
      setState
    );
  };

  useEffect(() => {
    updateLoadwheelOffset();
    window.addEventListener('scroll', updateLoadwheelOffset);
    return () => {
      window.removeEventListener('scroll', updateLoadwheelOffset);
    };
  }, []);

  useEffect(() => {
    updateLoadwheelOffset();
  }, [apiRequestState?.isLoading]);

  return (
    <div
      className={'w-full'}
      style={{ position: containerPosition ?? 'relative', minHeight: apiRequestState ? '3rem' : 'auto' }}
      ref={divRef}
    >
      {apiRequestState?.isError && !submodule && errorWarning}
      {apiRequestState?.isError && submodule && <CPageModule submodule>{errorWarning}</CPageModule>}
      {!apiRequestState?.isError && (showContent === undefined || showContent) && (
        <div
          style={{ opacity: apiRequestState?.isLoading ? 0.5 : 1 }}
          // className={'transition-opacity ease-in-out duration-1000'}
        >
          {children}
        </div>
      )}
      {apiRequestState?.isLoading && (
        <div
          className={'absolute h-full top-0 left-0 w-full flex flex-col z-10 pointer-events-none overflow-hidden'}
          ref={ref}
        >
          <div className={'flex flex-col h-full items-center justify-center overflow-hidden'}>
            <span style={{ position: 'absolute', top: state.loadWheelOffset }}>
              <LoadWheel />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

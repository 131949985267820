import { Alert } from '@mui/material';
import React from 'react';

import CLocalizedText from '../CLocalizedText';

export default function XDEmptyResult() {
  return (
    <Alert severity={'info'}>
      <CLocalizedText dictKey={'errorEmptyList'} />
    </Alert>
  );
}

import React from 'react';

import { BreakpointsInPx } from '../../enums/BreakpointsInPx';
import { useAppSelector } from '../../hooks/hooks';

interface ActionButtonWrapperProps {
  children?: React.ReactNode;
  className?: string;
  widerBox?: boolean;
  borderlessContent?: boolean;
  type?: 'gallery' | 'list' | 'doubleList';
}

/**
 * @deprecated Use CResponsiveGrid instead.
 * @param children
 * @param className
 * @param widerBox
 * @param borderlessContent
 * @param type
 * @param toolbar
 * @constructor
 */
export default function XDResponsiveGridWrapper({
  children,
  className,
  widerBox,
  borderlessContent,
  type
}: ActionButtonWrapperProps) {
  const { dashboardOutletDimensions } = useAppSelector((state) => state.dashboard);

  const columnSpan =
    dashboardOutletDimensions.w <= BreakpointsInPx.BREAKPOINT_1
      ? 1
      : dashboardOutletDimensions.w <= BreakpointsInPx.BREAKPOINT_2
        ? 2 - (widerBox ? 1 : 0)
        : dashboardOutletDimensions.w <= BreakpointsInPx.BREAKPOINT_3
          ? 3 - (widerBox ? 1 : 0)
          : dashboardOutletDimensions.w <= BreakpointsInPx.BREAKPOINT_4
            ? 4 - (widerBox ? 1 : 0)
            : 4 - (widerBox ? 1 : 0);

  const galleryWrapper = (
    <div
      className={
        `overflow-hidden grid align-start auto-cols-fr ${className ?? ''} ` +
        `${borderlessContent ? 'gap-2 -mx-2' : 'gap-4'} `
      }
      style={{
        gridTemplateColumns: `repeat(${columnSpan}, minmax(0, 1fr))`
      }}
    >
      {children}
    </div>
  );

  const listWrapper = <div className={'overflow-hidden flex flex-col gap-4'}>{children}</div>;

  const doubleListWrapper = <div className={'overflow-hidden grid grid-cols-1 sm:grid-cols-2 gap-4'}>{children}</div>;

  return (
    <>
      {!type && galleryWrapper}
      {type === 'gallery' && galleryWrapper}
      {type === 'list' && listWrapper}
      {type === 'doubleList' && doubleListWrapper}
    </>
  );
}

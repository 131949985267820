import { Alert } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import CFactsheetDisclaimer from '../../../components/CFactsheetDisclaimer';
import CLocalizedText from '../../../components/CLocalizedText';
import ImageWrapper from '../../../components/ImageWrapper';
import NotFoundModule from '../../../components/PageGates/NotFoundModule';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import { PageRoutes } from '../../../enums/enums';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import type { GhostPostModel } from '../../../models/GhostPostModel';
import type { IRequestState } from '../../../models/IRequestState';
import NewsService from '../../../services/newsService';
import { addBreadcrumbTextReplacement } from '../../../store/appSlice';

interface State {
  post?: GhostPostModel;
  disclaimer?: string;
  reqStatus: IRequestState;
}

export default function ArticlesEntry() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { locale } = useAppSelector((state) => state.dashboard);

  const [state, setState] = useState<State>({
    reqStatus: { isLoading: true, isError: false }
  });
  const updateState = (o: Partial<State>) => {
    setState((state) => ({ ...state, ...o }));
  };
  const { post, reqStatus } = state;

  const loadPost = useCallback(async () => {
    updateState({ reqStatus: { ...reqStatus, isLoading: true } });
    try {
      const slug = location.pathname.split('/').slice(-1).pop() ?? '';
      const res = await NewsService.getPost(slug);
      updateState({
        post: res.posts[0],
        disclaimer: res.disclaimer,
        reqStatus: { ...reqStatus, isLoading: false }
      });
      dispatch(addBreadcrumbTextReplacement([slug, res.posts[0].title]));
    } catch (e) {
      updateState({ reqStatus: { isLoading: false, isError: true } });
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    (async () => {
      await loadPost();
    })();
  }, [loadPost]);

  return (
    <>
      {state.reqStatus.isError && !state.reqStatus.isLoading && <NotFoundModule />}
      {(!state.reqStatus.isError || state.reqStatus.isLoading) && (
        <Page>
          <PageModule
            apiRequestState={reqStatus}
            errorMessage={<Alert severity={'error'}>The requested article could not be found</Alert>}
          >
            {post && (
              <>
                <div className={'flex gap-2'}>
                  {post?.tags?.map((e, ix) => {
                    return (
                      <span key={e.slug}>
                        <Link
                          to={`../../../${PageRoutes.DASHBOARD_OUTLET}/${PageRoutes.NEWS_OUTLET}/${PageRoutes.NEWS_ARTICLES_OUTLET}?tag=${e.slug}`}
                        >
                          <span className={'uppercase font-bold'}>{e.name}</span>
                          {ix + 1 !== (post?.tags?.length ?? 0) && <span>, </span>}
                        </Link>
                      </span>
                    );
                  })}
                </div>
                <h1 className={'text-5xl'}>{post.title}</h1>
                <div>
                  <p className={'font-bold'}>{post.authors.map((x) => x.name).join(', ')}</p>
                  <p>
                    {new Date(post.created_at).toLocaleString(locale, {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      timeZoneName: 'short'
                    })}{' '}
                    {!!post.reading_time && (
                      <>
                        &#8729; {post.reading_time}&nbsp;
                        <CLocalizedText dictKey={'newsMinsReadingTime'} inline />
                      </>
                    )}
                  </p>
                </div>
              </>
            )}
          </PageModule>
          {post?.feature_image && (
            <figure className={'overflow-hidden m-auto w-full'} style={{ maxWidth: '1000px' }}>
              <ImageWrapper
                src={post.feature_image}
                alt={post.feature_image_caption}
                className={'h-full w-full object-cover text-xl'}
                hideOnFail
              />
              {post.feature_image_caption && (
                <figcaption dangerouslySetInnerHTML={{ __html: post.feature_image_caption }} />
              )}
            </figure>
          )}
          {post && (
            <PageModule>
              <div className={'post-content'} dangerouslySetInnerHTML={{ __html: post.html }} />
            </PageModule>
          )}
          {/* {post && ( */}
          {/*   <PageModule background={<div className={'bg-slate-50'} />}> */}
          {/*     <p className={'uppercase text-sm font-bold'}>About the author{post.authors.length > 1 ? 's' : ''}</p> */}
          {/*     <div className={'flex flex-col gap-4'}> */}
          {/*       {post.authors?.map((x) => ( */}
          {/*         <div className={'flex gap-5'} key={x.id}> */}
          {/*           <ImageWrapper */}
          {/*             src={x.profile_image} */}
          {/*             alt={x.name} */}
          {/*             hideOnFail */}
          {/*             className={'object-cover aspect-square w-20 h-20 border rounded-full overflow-hidden flex-none flex'} */}
          {/*           /> */}
          {/*           <div className={'flex flex-col gap-2 flex-grow justify-center'}> */}
          {/*             <h5 className={'text-3xl text-teal-1'}>{x.name}</h5> */}
          {/*             <p>{x.bio}</p> */}
          {/*           </div> */}
          {/*         </div> */}
          {/*       ))} */}
          {/*     </div> */}
          {/*   </PageModule> */}
          {/* )} */}
          {post && <CFactsheetDisclaimer />}
        </Page>
      )}
    </>
  );
}

import { Close, Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import React, { useState } from 'react';

import { updateState } from '../helpers/helper';
import CLocalizedText from './CLocalizedText';
import CustomTextField from './CustomTextField';
import XDButton from './buttons/XDButton';

interface Props {
  label?: string | JSX.Element;
  placeholder?: string;
  onSubmit: (text: string) => void;
  continuousSubmission?: boolean;
  disabled?: boolean;
  initSearchText?: string;
}

interface State {
  value?: string;
  submitted: string | undefined;
}

export default function CSearchBar(props: Props) {
  const [state, setState] = useState<State>({
    value: props.initSearchText,
    submitted: props.initSearchText ?? undefined
  });

  const handleSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit(state.value ?? '');
      updateState<State>({ submitted: state.value }, state, setState);
    }
  };

  const handleClear = () => {
    if (props.onSubmit) {
      props.onSubmit('');
      updateState<State>({ value: '', submitted: undefined }, state, setState);
    }
  };

  return (
    <CustomTextField
      fullWidth
      label={props.label ?? <CLocalizedText dictKey={'globalSearchCompany'} />}
      value={state.value}
      onChange={(ev) => {
        updateState<State>({ value: ev.target.value }, state, setState);
        if (props.continuousSubmission) props.onSubmit(ev.target.value);
      }}
      onKeyDown={async (e) => {
        if (e.key === 'Enter') {
          handleSubmit();
          (document.activeElement as HTMLElement).blur(); // Blur all input, yes this is hacky
        }
      }}
      placeholder={props.placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'} sx={{ marginRight: '-0.25rem' }}>
            {(state.submitted !== state.value || !state.value) && (
              <XDButton onClick={handleSubmit} className={'text-2xl'} disabled={props.disabled}>
                <Search />
              </XDButton>
            )}
            {state.submitted && state.submitted === state.value && (
              <XDButton onClick={handleClear} className={'text-2xl'} disabled={props.disabled}>
                <Close />
              </XDButton>
            )}
          </InputAdornment>
        )
      }}
    />
  );
}

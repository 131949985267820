import { UserCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CStatefulContainer from '../../components/CStatefulContainer';
import TagButton from '../../components/TagButton';
import ActionButton from '../../components/dashboardContent/ActionButton';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import XDResponsiveGridWrapper from '../../components/layout/XDResponsiveGridWrapper';
import { ETonalButtonClassNames } from '../../enums/ETagButtonColors';
import { PageRoutes } from '../../enums/enums';
import { useAppDispatch } from '../../hooks/hooks';
import { type IRequestState } from '../../models/IRequestState';
import UserManagementService from '../../services/userManagementService';
import { updateUserObject } from '../../store/userSlice';

interface State {
  searchReqState: IRequestState;
}

export default function UsersOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [keycloakUsers, setKeycloakUsers] = useState<
    Array<{
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      isStaff: boolean;
      isAdmin: boolean;
      isUser: boolean;
    }>
  >([]);
  const [state, setState] = useState<State>({ searchReqState: { isError: false, isLoading: true } });
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { searchReqState } = state;

  const loadUsers = async () => {
    updateState({ searchReqState: { isLoading: true, isError: false } });
    const users = await UserManagementService.getKeycloakUsers();
    if (users.hasError()) {
      updateState({ searchReqState: { isLoading: false, isError: true } });
    } else {
      setKeycloakUsers(users.getData().users);
      updateState({ searchReqState: { isLoading: false, isError: false } });
    }
  };

  useEffect(() => {
    loadUsers().then();
  }, []);

  return (
    <Page>
      <PageModule>
        <PageModuleTitle>All Accounts</PageModuleTitle>
        <CStatefulContainer apiRequestState={searchReqState}>
          <XDResponsiveGridWrapper type={'doubleList'}>
            {keycloakUsers.map((e) => (
              <ActionButton
                type={'list'}
                key={e.id}
                label={
                  <div className={'flex flex-wrap gap-2 items-center'}>
                    <h3>{[e.firstName, e.lastName].join(' ')}</h3>
                    {e.isStaff && <TagButton color={ETonalButtonClassNames.TEAL_GREEN_1}>Staff</TagButton>}
                    {e.isAdmin && <TagButton color={ETonalButtonClassNames.TEAL_COMP_5}>Admin</TagButton>}
                    {e.isUser && <TagButton color={ETonalButtonClassNames.TEAL_PPT_1}>You</TagButton>}
                  </div>
                }
                sublabel={e.email}
                standardCase
                flipOrder
                onClick={() => {
                  dispatch(
                    updateUserObject({
                      email: e.email,
                      firstName: e.firstName,
                      lastName: e.lastName,
                      id: e.id,
                      isUser: e.isUser,
                      isAdmin: e.isAdmin,
                      isStaff: e.isStaff
                    })
                  );
                  navigate(PageRoutes.USERS_USER_HUB_OUTLET);
                }}
              >
                <UserCircleIcon />
              </ActionButton>
            ))}
          </XDResponsiveGridWrapper>
        </CStatefulContainer>
      </PageModule>
    </Page>
  );
}

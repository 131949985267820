import { ArrowForward } from '@mui/icons-material';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomTextField from '../../components/CustomTextField';
import CustomSelectDropdown from '../../components/general/CustomSelectDropdown';
import PaddedButton from '../../components/general/PaddedButton';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleForm from '../../components/layout/PageModuleForm';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import { PageRoutes } from '../../enums/enums';
import { useAppDispatch } from '../../hooks/hooks';
import { type Role } from '../../models/UserAccountModel';
import UserManagementService from '../../services/userManagementService';
import { addBreadcrumbTextReplacement, updateSnackbar } from '../../store/appSlice';
import { updateUserObject } from '../../store/userSlice';

export default function CreateUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [role, setRole] = useState<Role>('user');
  const [totp, setTotp] = useState(false);

  const submitForm = async () => {
    if (!email || !firstName || !lastName || !role) {
      dispatch(
        updateSnackbar({
          severity: 'error',
          message: 'Please ensure that all fields are filled before submitting.'
        })
      );
    } else {
      const response = await UserManagementService.createKeycloakUser({
        firstName,
        lastName,
        email,
        roles: [role],
        totp
      });
      if (response.hasError()) {
        dispatch(updateSnackbar({ severity: 'error', message: response.getErrorDeprecate().error.message }));
      } else {
        dispatch(
          updateUserObject({
            email,
            firstName,
            lastName,
            id: response.getData().userId
          })
        );
        navigate(`../${PageRoutes.USERS_OVERVIEW_OUTLET}`);
      }
    }
  };

  useEffect(() => {
    dispatch(addBreadcrumbTextReplacement([PageRoutes.USERS_NEW, 'New User']));
  }, [dispatch]);

  return (
    <Page>
      <PageModule>
        <PageModuleTitle>Create a new user account</PageModuleTitle>
        <PageModuleForm title={'Personal details'}>
          <div className={'grid grid-cols-1 sm:grid-cols-2 gap-4'}>
            <CustomTextField
              label="First name"
              placeholder={'Type in first name'}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <CustomTextField
              label="Last name"
              placeholder={'Type in last name'}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
        </PageModuleForm>
        <PageModuleForm title={'Account details'}>
          <CustomTextField
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div className={'flex gap-4 flex-wrap'}>
            <CustomSelectDropdown
              label={'Role'}
              className={'capitalize'}
              value={role}
              onChange={(e) => {
                setRole(e.target.value as Role);
              }}
              growOnMobile
            >
              {['user', 'staff', 'admin'].map((e) => (
                <MenuItem key={e} value={e} className={'capitalize'}>
                  {e}
                </MenuItem>
              ))}
            </CustomSelectDropdown>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  checked={totp}
                  onChange={(e) => {
                    setTotp(e.target.checked);
                  }}
                />
              }
              label="Enable two-factor authentication"
              labelPlacement="end"
            />
          </div>
        </PageModuleForm>
        <PageModuleForm>
          <PaddedButton
            className={'my-6'}
            label={'Submit'}
            onClick={submitForm}
            icon={<ArrowForward />}
            trailingIcon
            growOnMobile
          />
        </PageModuleForm>
      </PageModule>
    </Page>
  );
}

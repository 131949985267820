import { ArrowTopRightOnSquareIcon, DocumentChartBarIcon } from '@heroicons/react/24/outline';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';

import TagButton from '../../../components/TagButton';
import ActionLinkButton from '../../../components/dashboardContent/ActionLinkButton';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import PageModuleTitle from '../../../components/layout/PageModuleTitle';
import XDResponsiveGridWrapper from '../../../components/layout/XDResponsiveGridWrapper';
import { ETonalButtonClassNames } from '../../../enums/ETagButtonColors';
import { PageRoutes } from '../../../enums/enums';
import { updateState } from '../../../helpers/helper';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import UserManagementService from '../../../services/userManagementService';
import { updateSnackbar } from '../../../store/appSlice';

interface State {
  adminUrl?: string;
}

export default function UserManagementIndex() {
  const { firstName, lastName, email, id, isAdmin, isStaff, isUser } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const keycloak = useKeycloak();

  const [state, setState] = useState<State>({});

  useEffect(() => {
    const retrieveAdminUrl = async () => {
      const res = await UserManagementService.getKeycloakAdminUrl();
      if (!res.hasError()) {
        updateState<State>({ adminUrl: res.getData().url }, state, setState);
      } else {
        dispatch(updateSnackbar({ message: res.getErrorString(), severity: 'error' }));
      }
    };

    (async () => {
      await retrieveAdminUrl();
    })();
  }, []);

  return (
    <Page>
      <PageModule>
        <PageModuleTitle subtitle={email} standardCase flipOrder>
          <div className={'flex flex-wrap gap-2 items-center'}>
            <h1>{[firstName, lastName].join(' ')}</h1>
            {isAdmin && <TagButton color={ETonalButtonClassNames.TEAL_PPT_4}>Admin</TagButton>}
            {isStaff && <TagButton color={ETonalButtonClassNames.TEAL_GREEN_1}>Staff</TagButton>}
            {isUser && <TagButton color={ETonalButtonClassNames.TEAL_PPT_1}>You</TagButton>}
          </div>
        </PageModuleTitle>
        <XDResponsiveGridWrapper>
          <ActionLinkButton label="Backtest access" to={PageRoutes.USERS_BACKTEST} disabled>
            <DocumentChartBarIcon />
          </ActionLinkButton>
          {state.adminUrl && (
            <ActionLinkButton
              label="View in Keycloak"
              target={'_blank'}
              to={`${state.adminUrl}/admin/${keycloak.keycloak.realm}/console/#/${keycloak.keycloak.realm}/users/${id}/settings`}
            >
              <ArrowTopRightOnSquareIcon />
            </ActionLinkButton>
          )}
          {/* < ActionLinkButton label='Security & Privacy' to={PageRoutes.USERS_DELETE}> */}
          {/*   <LockClosedIcon/> */}
          {/* </ActionLinkButton> */}
        </XDResponsiveGridWrapper>
      </PageModule>
    </Page>
  );
}

import { KeyboardBackspace, KeyboardTab } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { type PaginationMeta } from '../../models/GhostPostModel';
import { type IRequestState } from '../../models/IRequestState';
import { scrollToTop } from '../../utils/utils';
import CLocalizedText from '../CLocalizedText';
import CustomTextField from '../CustomTextField';
import StatefulModuleDisabler from '../StatefulModuleDisabler';
import XDButton from '../buttons/XDButton';

interface Props {
  pagination: PaginationMeta | undefined;
  retrievePageCallback: (pageNumber: number) => void;
  className?: string;
  maxPageListing?: number;
  prevLabel?: string | JSX.Element;
  nextLabel?: string | JSX.Element;
  firstLabel?: string | JSX.Element;
  lastLabel?: string | JSX.Element;
  disabled?: boolean;
  dontScrollOnReload?: boolean;
  apiReqState: IRequestState | undefined;
}

interface State {
  pageInput: number;
}

export default function XDPaginator({
  pagination,
  retrievePageCallback,
  className,
  maxPageListing,
  prevLabel,
  nextLabel,
  firstLabel,
  lastLabel,
  disabled,
  dontScrollOnReload,
  apiReqState
}: Props) {
  if (!maxPageListing) {
    // Should be an odd number
    maxPageListing = 5;
  }

  const [state, setState] = useState<State>({ pageInput: 1 });
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { pageInput } = state;

  const pageHandler = (
    e:
      | React.MouseEvent
      | React.KeyboardEvent<HTMLDivElement>
      | React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
    pageNumber: number | null
  ) => {
    e.stopPropagation();
    if (!pageNumber) return;
    if (pageNumber > (pagination?.pages ?? 0)) {
      updateState({ pageInput: pagination?.pages ?? 0 });
      retrievePageCallback(pagination?.pages ?? 0); // Jump to last page if erroneous entry
    } else {
      retrievePageCallback(pageNumber);
    }
    if (!dontScrollOnReload) scrollToTop();
  };

  useEffect(() => {
    updateState({ pageInput: pagination?.page ?? 1 });
  }, [pagination?.page]);

  return (
    <>
      {pagination && pagination.pages > 1 && (
        <StatefulModuleDisabler disabled={disabled ?? apiReqState?.isLoading ?? false}>
          <div
            className={`flex flex-wrap gap-0 items-center justify-center relative min-h-[3.5rem] ${className ?? ''}`}
          >
            {pagination.pages > maxPageListing && (
              <PaginatorButton
                onClick={(e) => {
                  pageHandler(e, 1);
                }}
                disabled={!pagination.prev}
              >
                {firstLabel ?? <KeyboardTab style={{ transform: 'rotate(180deg)' }} />}
              </PaginatorButton>
            )}
            <PaginatorButton
              onClick={(e) => {
                pageHandler(e, pagination.prev);
              }}
              disabled={!pagination.prev}
            >
              {prevLabel ?? <KeyboardBackspace />}
            </PaginatorButton>
            {pagination.pages > maxPageListing && (
              <div className={'w-28 px-2'}>
                <CustomTextField
                  label={<CLocalizedText dictKey={'globalPage'} />}
                  value={pageInput}
                  type={'number'}
                  // helperText={'Hit enter to update'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'} className={'mt-4'}>{`/ ${pagination.pages}`}</InputAdornment>
                    )
                  }}
                  onChange={(e) => {
                    updateState({ pageInput: Number.parseInt(e.target.value) });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      (document.activeElement as HTMLElement).blur(); // Blur all input, yes this is hacky
                    }
                  }}
                  onBlur={(e) => {
                    pageHandler(e, Number.parseInt(e.target.value));
                  }}
                />
              </div>
            )}
            {pagination.pages <= maxPageListing &&
              Array(pagination.pages)
                .fill(undefined)
                .map((e, ix) => ix)
                .slice(
                  pagination.pages - pagination.page < Math.floor(maxPageListing / 2) &&
                    pagination.pages > maxPageListing
                    ? pagination.pages - maxPageListing
                    : pagination.pages <= maxPageListing
                      ? 0
                      : Math.max(0, pagination.page - (Math.floor(maxPageListing / 2) + 1)),
                  Math.max(maxPageListing, pagination.page + Math.floor(maxPageListing / 2))
                )
                .map((e, ix) => (
                  <PaginatorButton
                    key={ix}
                    onClick={(ev) => {
                      pageHandler(ev, e + 1);
                    }}
                    isSelected={e + 1 === pagination.page}
                  >
                    {(e + 1).toString()}
                  </PaginatorButton>
                ))}
            <PaginatorButton
              onClick={(e) => {
                pageHandler(e, pagination.next);
              }}
              disabled={!pagination.next}
            >
              {nextLabel ?? <KeyboardBackspace style={{ transform: 'rotate(180deg)' }} />}
            </PaginatorButton>
            {pagination.pages > maxPageListing && (
              <PaginatorButton
                onClick={(e) => {
                  pageHandler(e, pagination.pages);
                }}
                disabled={!pagination.next}
              >
                {lastLabel ?? <KeyboardTab />}
              </PaginatorButton>
            )}
          </div>
        </StatefulModuleDisabler>
      )}
    </>
  );
}

interface ButtonProps {
  children: JSX.Element | string;
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
  isSelected?: boolean;
}

function PaginatorButton({ children, onClick, disabled, isSelected }: ButtonProps) {
  return (
    <XDButton
      onClick={onClick}
      disabled={disabled}
      className={`${isSelected ? 'text-teal-ppt-1 font-black' : 'font-normal'} justify-center`}
      style={{ minWidth: '3rem', minHeight: '3.5rem' }}
    >
      {children}
    </XDButton>
  );
}

import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateState } from '../../helpers/helper';
import { type IRequestState } from '../../models/IRequestState';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

interface State {
  reqState: IRequestState;
}

export default function PublicFactsheetIndex() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [state, setState] = useState<State>({ reqState: { isError: false, isLoading: false } });

  useEffect(() => {
    (async () => {
      if (keycloak.authenticated) {
        updateState<State>({ reqState: { isLoading: true, isError: false } }, state, setState);
        const res = await PublicFactsheetServices.getLastViewedFactsheet();
        updateState<State>(
          {
            reqState: {
              isLoading: false,
              isError: res.hasError(),
              strError: (<CLocalizedText dictKey={'factsheetOutletRequestLink'} />) as unknown as string
            }
          },
          state,
          setState
        );
        if (!res.hasError()) {
          navigate(`/${PageRoutes.PUBLIC_FACTSHEET_COLLECTION_OUTLET}/${res.getData().id}`);
        }
      } else {
        navigate('..');
      }
    })();
  }, []);

  return (
    <Page>
      <CPageModule apiRequestState={state.reqState} />
    </Page>
  );
}

import { MoreVert } from '@mui/icons-material';
import React from 'react';

import XDButton from './buttons/XDButton';
import XDDropdown from './selection/XDDropdown';

interface KebabBarProps {
  children: React.ReactNode;
  dropdownItems: React.ReactNode;
}

export default function CKebabBar(props: KebabBarProps) {
  return (
    <div className={'flex flex-nowrap gap-4'}>
      <div className={'flex-grow'}>{props.children}</div>
      <div className={'flex-shrink'}>
        <div className={'-mr-3'}>
          <XDDropdown
            button={
              <XDButton className={'h-12 aspect-square items-center justify-center text-xl'}>
                <MoreVert />
              </XDButton>
            }
          >
            {props.dropdownItems}
          </XDDropdown>
        </div>
      </div>
    </div>
  );
}

export enum ETonalButtonClassNames {
  DEFAULT = 'bg-gray-800 bg-opacity-10 text-gray-800 border-gray-800',
  TEAL_GREEN_1 = 'bg-teal-green-1 bg-opacity-10 text-teal-green-1 border-teal-green-1',
  TEAL_GREEN_2 = 'bg-teal-green-2 bg-opacity-10 text-teal-green-2 border-teal-green-2',
  TEAL_GREEN_2_ON_WHITE = 'bg-teal-green-2 bg-opacity-0 text-teal-green-2 border-teal-green-2',
  TEAL_GREEN_3 = 'bg-teal-green-3 bg-opacity-10 text-teal-green-3 border-teal-green-3',
  TEAL_GREEN_4 = 'bg-teal-green-4 bg-opacity-10 text-teal-green-4 border-teal-green-4',
  TEAL_GREEN_5 = 'bg-teal-green-5 bg-opacity-10 text-teal-green-5 border-teal-green-5',
  TEAL_COMP_1 = 'bg-teal-comp-1 bg-opacity-10 text-teal-comp-1 border-teal-comp-1',
  TEAL_COMP_2 = 'bg-teal-comp-2 bg-opacity-10 text-teal-comp-2 border-teal-comp-2',
  TEAL_COMP_3 = 'bg-teal-comp-3 bg-opacity-10 text-teal-comp-3 border-teal-comp-3',
  TEAL_COMP_4 = 'bg-teal-comp-4 bg-opacity-10 text-teal-comp-4 border-teal-comp-4',
  TEAL_COMP_5 = 'bg-teal-comp-5 bg-opacity-10 text-teal-comp-5 border-teal-comp-5',
  TEAL_PPT_1 = 'bg-teal-ppt-1 bg-opacity-10 text-teal-ppt-1 border-teal-ppt-1',
  TEAL_PPT_2 = 'bg-teal-ppt-2 bg-opacity-10 text-teal-ppt-2 border-teal-ppt-2',
  TEAL_PPT_3 = 'bg-teal-ppt-3 bg-opacity-10 text-teal-ppt-3 border-teal-ppt-3',
  TEAL_PPT_4 = 'bg-teal-ppt-4 bg-opacity-10 text-teal-ppt-4 border-teal-ppt-4',
  TEAL_PPT_5 = 'bg-teal-ppt-5 bg-opacity-10 text-teal-ppt-5 border-teal-ppt-5',
  TEAL_PPT_6 = 'bg-teal-ppt-6 bg-opacity-10 text-teal-ppt-6 border-teal-ppt-6',
  TEAL_PPT_7 = 'bg-teal-ppt-7 bg-opacity-10 text-teal-ppt-7 border-teal-ppt-7',
  TEAL_PPT_8 = 'bg-teal-ppt-8 bg-opacity-10 text-teal-ppt-8 border-teal-ppt-8',
  TEAL_PPT_9 = 'bg-teal-ppt-9 bg-opacity-10 text-teal-ppt-9 border-teal-ppt-9'
}

import { Alert, type AlertProps } from '@mui/material';
import React from 'react';

import CLocalizedText from './CLocalizedText';

interface CAlertProps extends AlertProps {
  children?: React.ReactNode;
}

export default function CAlert(props: CAlertProps) {
  const propsWithoutChildren = { ...props };
  delete propsWithoutChildren.children;

  let errorNode: React.ReactNode | undefined;
  switch (props.children) {
    case 'nanInResponse':
      errorNode = <CLocalizedText dictKey={'globalErrorNanInResponse'} />;
      break;
    case undefined:
      errorNode = <CLocalizedText dictKey={'globalErrorUnknown'} />;
      break;
    default:
      errorNode = <span>{props.children}</span>;
      break;
  }

  return <Alert {...propsWithoutChildren}>{errorNode}</Alert>;
}

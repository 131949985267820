import { Checkbox } from '@mui/material';
import React, { type CSSProperties, useRef, useState } from 'react';

import { updateState } from '../../helpers/helper';
import XDButton from '../buttons/XDButton';

interface props {
  children: string | JSX.Element;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
}

interface State {
  isHover?: boolean;
}

export default function XDCheckbox(props: props) {
  const labelRef = useRef<HTMLLabelElement>(null);
  const [state, setState] = useState<State>({});

  const handleClick = () => {
    labelRef.current?.click();
  };

  return (
    <XDButton
      onClick={handleClick}
      className={'hover:bg-opacity-0'}
      style={props.style}
      onMouseEnter={() => {
        updateState<State>({ isHover: true }, state, setState);
      }}
      onMouseLeave={() => {
        updateState<State>({ isHover: false }, state, setState);
      }}
    >
      <label ref={labelRef} className={'flex gap-2 items-start cursor-pointer pointer-events-none -ml-2'}>
        <Checkbox
          checked={props.checked}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, padding: 0 }}
          disableRipple
          onChange={props.onChange}
        />
        <p
          className={'-ml-0 flex w-full pointer-events-none normal-case text-left'}
          style={{ opacity: state.isHover ? 0.8 : 1 }}
        >
          {props.children}
        </p>
      </label>
    </XDButton>
  );
}

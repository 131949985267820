import { OpenInNew } from '@mui/icons-material';
import React from 'react';

import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CResponsiveGrid from '../../components/CResponsiveGrid';
import CTab from '../../components/CTab';
import CTabItem from '../../components/CTabItem';
import CTextFieldDisplay from '../../components/CTextFieldDisplay';
import { BreakpointsInPx } from '../../enums/BreakpointsInPx';
import { useAppSelector } from '../../hooks/hooks';

export default function CAnteilsklasseTab() {
  const { dashboardOutletDimensions } = useAppSelector((state) => state.dashboard);

  // Class S

  const anteilsklasseS1suf = (
    <>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3Launch'} />}>
        <CLocalizedText dictKey={'fsovP3LaunchClassValS'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3LaunchClass'} />}>
        <CLocalizedText dictKey={'fsovP3LaunchClassValS'} />
      </CTextFieldDisplay>
    </>
  );
  const anteilsklasseS2pre = (
    <>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3EsgRatingKey'} />}>
        <CLocalizedText dictKey={'fsovP3EsgRatingVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3UtilOfIncKey'} />}>
        <CLocalizedText dictKey={'fsovP3UtilOfIncVal'} />
      </CTextFieldDisplay>
    </>
  );
  const anteilsklasseS1 = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3IsinKey'} />}>
        <CLocalizedText dictKey={'fsovP3IsinValS'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3DomicileKey'} />}>
        <CLocalizedText dictKey={'fsovP3DomicileVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3FundCatKey'} />}>
        <CLocalizedText dictKey={'fsovP3FundCatVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3CurFundClassKey'} />}>
        <CLocalizedText dictKey={'fsovP3CurFundClassVal'} />
      </CTextFieldDisplay>
      {dashboardOutletDimensions.w < BreakpointsInPx.BREAKPOINT_3 && anteilsklasseS1suf}
    </CPageModule>
  );
  const anteilsklasseS1a = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      {anteilsklasseS1suf}
      {anteilsklasseS2pre}
    </CPageModule>
  );
  const anteilsklasseS2 = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      {dashboardOutletDimensions.w < BreakpointsInPx.BREAKPOINT_3 && anteilsklasseS2pre}
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxManFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxManFeeVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxDepFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxDepFeeVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3OngoingCostKey'} />}>
        <CLocalizedText dictKey={'fsovP3OngoingCostValS'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxAdvFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxAdvFeeValS'} />
      </CTextFieldDisplay>
    </CPageModule>
  );

  // Class I

  const anteilsklasseI1suf = (
    <>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3Launch'} />}>
        <CLocalizedText dictKey={'fsovP3LaunchClassValI'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3LaunchClass'} />}>
        <CLocalizedText dictKey={'fsovP3LaunchClassValI'} />
      </CTextFieldDisplay>
    </>
  );
  const anteilsklasseI2pre = (
    <>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3EsgRatingKey'} />}>
        <CLocalizedText dictKey={'fsovP3EsgRatingVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3UtilOfIncKey'} />}>
        <CLocalizedText dictKey={'fsovP3UtilOfIncVal'} />
      </CTextFieldDisplay>
    </>
  );
  const anteilsklasseI1 = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3IsinKey'} />}>
        <CLocalizedText dictKey={'fsovP3IsinValI'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3DomicileKey'} />}>
        <CLocalizedText dictKey={'fsovP3DomicileVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3FundCatKey'} />}>
        <CLocalizedText dictKey={'fsovP3FundCatVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3CurFundClassKey'} />}>
        <CLocalizedText dictKey={'fsovP3CurFundClassVal'} />
      </CTextFieldDisplay>
      {dashboardOutletDimensions.w < BreakpointsInPx.BREAKPOINT_3 && anteilsklasseI1suf}
    </CPageModule>
  );
  const anteilsklasseI1a = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      {anteilsklasseI1suf}
      {anteilsklasseI2pre}
    </CPageModule>
  );
  const anteilsklasseI2 = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      {dashboardOutletDimensions.w < BreakpointsInPx.BREAKPOINT_3 && anteilsklasseI2pre}
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxManFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxManFeeVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxDepFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxDepFeeVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3OngoingCostKey'} />}>
        <CLocalizedText dictKey={'fsovP3OngoingCostValI'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxAdvFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxAdvFeeValI'} />
      </CTextFieldDisplay>
    </CPageModule>
  );

  // Class R

  const anteilsklasseR1suf = (
    <>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3Launch'} />}>
        <CLocalizedText dictKey={'fsovP3LaunchClassValR'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3LaunchClass'} />}>
        <CLocalizedText dictKey={'fsovP3LaunchClassValR'} />
      </CTextFieldDisplay>
    </>
  );
  const anteilsklasseR2pre = (
    <>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3EsgRatingKey'} />}>
        <CLocalizedText dictKey={'fsovP3EsgRatingVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3UtilOfIncKey'} />}>
        <CLocalizedText dictKey={'fsovP3UtilOfIncVal'} />
      </CTextFieldDisplay>
    </>
  );
  const anteilsklasseR1 = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3IsinKey'} />}>
        <CLocalizedText dictKey={'fsovP3IsinValR'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3DomicileKey'} />}>
        <CLocalizedText dictKey={'fsovP3DomicileVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3FundCatKey'} />}>
        <CLocalizedText dictKey={'fsovP3FundCatVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3CurFundClassKey'} />}>
        <CLocalizedText dictKey={'fsovP3CurFundClassVal'} />
      </CTextFieldDisplay>
      {dashboardOutletDimensions.w < BreakpointsInPx.BREAKPOINT_3 && anteilsklasseI1suf}
    </CPageModule>
  );
  const anteilsklasseR1a = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      {anteilsklasseR1suf}
      {anteilsklasseR2pre}
    </CPageModule>
  );
  const anteilsklasseR2 = (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      {dashboardOutletDimensions.w < BreakpointsInPx.BREAKPOINT_3 && anteilsklasseI2pre}
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxManFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxManFeeVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxDepFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxDepFeeVal'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3OngoingCostKey'} />}>
        <CLocalizedText dictKey={'fsovP3OngoingCostValR'} />
      </CTextFieldDisplay>
      <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxAdvFeeKey'} />}>
        <CLocalizedText dictKey={'fsovP3MaxAdvFeeValR'} />
      </CTextFieldDisplay>
    </CPageModule>
  );

  return (
    <CTab submodule>
      <CTabItem label={'anteilsklasseS'} text={<CLocalizedText dictKey={'fsovP3TabS'} />}>
        <CPageModule submodule style={{ paddingTop: 0 }}>
          <h3 className={'flex justify-start'}>
            <a
              href={'https://fondsfinder.universal-investment.com/de/DE/Funds/DE000A3D9F52'}
              target={'_blank'}
              rel="noreferrer"
              className={'items-center text-xl flex gap-2 font-normal '}
            >
              <CLocalizedText dictKey={'fsovDescLinkS'} />
              <OpenInNew />
            </a>
          </h3>
          <CResponsiveGrid maxColumns={3} style={{ columnGap: '1.5rem', rowGap: '1.5rem' }}>
            {anteilsklasseS1}
            {dashboardOutletDimensions.w >= BreakpointsInPx.BREAKPOINT_3 && anteilsklasseS1a}
            {anteilsklasseS2}
          </CResponsiveGrid>
        </CPageModule>
      </CTabItem>
      <CTabItem label={'anteilsklasseI'} text={<CLocalizedText dictKey={'fsovP3TabI'} />}>
        <CPageModule submodule style={{ paddingTop: 0 }}>
          <h3 className={'flex justify-start'}>
            <a
              href={'https://fondsfinder.universal-investment.com/de/DE/Funds/DE000A3E17X2'}
              target={'_blank'}
              rel="noreferrer"
              className={'items-center text-xl flex gap-2 font-normal '}
            >
              <CLocalizedText dictKey={'fsovDescLinkI'} />
              <OpenInNew />
            </a>
          </h3>
          <CResponsiveGrid maxColumns={3} style={{ columnGap: '1.5rem', rowGap: '1.5rem' }}>
            {anteilsklasseI1}
            {dashboardOutletDimensions.w >= BreakpointsInPx.BREAKPOINT_3 && anteilsklasseI1a}
            {anteilsklasseI2}
          </CResponsiveGrid>
        </CPageModule>
      </CTabItem>
      <CTabItem label={'anteilsklasseR'} text={<CLocalizedText dictKey={'fsovP3TabR'} />}>
        <CPageModule submodule style={{ paddingTop: 0 }}>
          <h3 className={'flex justify-start'}>
            <a
              href={'https://fondsfinder.universal-investment.com/de/DE/Funds/DE000A3E17Y0'}
              target={'_blank'}
              rel="noreferrer"
              className={'items-center text-xl flex gap-2 font-normal '}
            >
              <CLocalizedText dictKey={'fsovDescLinkR'} />
              <OpenInNew />
            </a>
          </h3>
          <CResponsiveGrid maxColumns={3} style={{ columnGap: '1.5rem', rowGap: '1.5rem' }}>
            {anteilsklasseR1}
            {dashboardOutletDimensions.w >= BreakpointsInPx.BREAKPOINT_3 && anteilsklasseR1a}
            {anteilsklasseR2}
          </CResponsiveGrid>
        </CPageModule>
      </CTabItem>
    </CTab>
  );
}

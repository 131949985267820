import { ArrowForward } from '@mui/icons-material';
import { Alert } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomTextField from '../../../components/CustomTextField';
import PageModuleFormGroup from '../../../components/PageModuleFormGroup';
import PageModuleFormRow from '../../../components/PageModuleFormRow';
import ButtonXd from '../../../components/dashboardContent/ButtonXd';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import PageModuleTitle from '../../../components/layout/PageModuleTitle';
import { PageRoutes } from '../../../enums/enums';
import { updateState } from '../../../helpers/helper';
import { type IRequestState } from '../../../models/IRequestState';
import { type FactsheetLinkCreationForm } from '../../../models/publicFactsheetModel';
import PublicFactsheetServices from '../../../services/publicFactsheetServices';

interface State {
  reqState: IRequestState;
  form: Partial<FactsheetLinkCreationForm>;
}

export default function CreateNewFactsheetLink() {
  const navigate = useNavigate();
  const [state, setState] = useState<State>({ form: {}, reqState: { isError: false, isLoading: false } });

  const handleFormChange = (e: React.ChangeEvent, partialForm: Partial<FactsheetLinkCreationForm>) => {
    if (partialForm.id) {
      partialForm.id = partialForm.id.slice(0, 6).toLowerCase();
    }
    updateState<State>({ form: { ...state.form, ...partialForm } }, state, setState);
  };

  const handleFormSubmit = async () => {
    updateState<State>({ reqState: { isLoading: true, isError: false } }, state, setState);
    const res = await PublicFactsheetServices.postFactsheetLink(state.form);
    updateState<State>(
      {
        reqState: {
          isLoading: false,
          isError: res.hasError(),
          objError: res.getErrorObj(),
          strError: res.getErrorString()
        }
      },
      state,
      setState
    );
    if (!res.hasError()) {
      navigate(
        `/${PageRoutes.PUBLISHING_OUTLET}/${PageRoutes.PUBLISHING_FS_OUTLET}/${PageRoutes.PUBLISHING_FS_OVERVIEW_OUTLET}/${res.getData().id}`
      );
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      await handleFormSubmit();
    }
  };

  return (
    <Page title={'New Factsheet Link'}>
      <PageModule>
        <PageModuleTitle>Create a new factsheet link</PageModuleTitle>
        <PageModuleFormGroup>
          <PageModuleFormRow
            description={
              'If blank, this value will be set by the system upon creation. This value cannot be changed afterwards.'
            }
          >
            <CustomTextField
              label={'6-character link ID (optional)'}
              className={'sm:w-full'}
              value={state.form.id}
              error={(state.reqState.objError?.id?.length ?? 0) > 0}
              helperText={state.reqState.objError?.id}
              onChange={(e) => {
                handleFormChange(e, { id: e.target.value });
              }}
              onKeyDown={handleKeyDown}
            />
          </PageModuleFormRow>
          <PageModuleFormRow
            description={
              'Description of the link. Only visible to administrators. If blank, this value will be set to the link ID by default.'
            }
          >
            <CustomTextField
              label={'Link name (optional)'}
              className={'sm:w-full'}
              value={state.form.name}
              onChange={(e) => {
                handleFormChange(e, { name: e.target.value });
              }}
              onKeyDown={handleKeyDown}
            />
          </PageModuleFormRow>
          <PageModuleFormRow description={'Email address of the user.'}>
            <CustomTextField
              label={'Email address (optional)'}
              className={'sm:w-full'}
              value={state.form.email}
              onChange={(e) => {
                handleFormChange(e, { email: e.target.value });
              }}
              onKeyDown={handleKeyDown}
            />
          </PageModuleFormRow>
          <PageModuleFormRow
            description={
              'Field to store additional notes or information about the link. Only visible to administrators.'
            }
          >
            <CustomTextField
              label={'Note (optional)'}
              className={'sm:w-full'}
              multiline
              rows={5}
              value={state.form.notes}
              onChange={(e) => {
                handleFormChange(e, { notes: e.target.value });
              }}
              onKeyDown={handleKeyDown}
            />
          </PageModuleFormRow>
          {/* <PageModuleFormRow description={'Optional message which can be displayed at the start of the factsheet as a banner, including in printout.'}> */}
          {/*   <CustomTextField label={'User message (optional)'} */}
          {/*                    className={'sm:w-full'} multiline */}
          {/*                    rows={5} */}
          {/*                    value={state.form.user_message} */}
          {/*                    onChange={(e) => { */}
          {/*                      handleFormChange(e, { user_message: e.target.value }); */}
          {/*                    }} */}
          {/*                    onKeyDown={handleKeyDown}/> */}
          {/* </PageModuleFormRow> */}
        </PageModuleFormGroup>
        <PageModuleFormGroup>
          {state.reqState.strError && (
            <PageModuleFormRow>
              <Alert severity={'error'}>{state.reqState.strError}</Alert>
            </PageModuleFormRow>
          )}
          <PageModuleFormRow>
            <ButtonXd trailingIcon state={state.reqState} icon={<ArrowForward />} onClick={handleFormSubmit}>
              Create Link
            </ButtonXd>
          </PageModuleFormRow>
        </PageModuleFormGroup>
      </PageModule>
    </Page>
  );
}

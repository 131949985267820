import { AddLink } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CKebabBar from '../../../components/CKebabBar';
import CLocalizedText from '../../../components/CLocalizedText';
import CLocalizedTextString from '../../../components/CLocalizedTextString';
import CPageModule from '../../../components/CPageModule';
import CPageModulePaginatedTable from '../../../components/CPageModulePaginatedTable';
import CStatefulContainer from '../../../components/CStatefulContainer';
import Page from '../../../components/layout/Page';
import XDDropdownItem from '../../../components/selection/XDDropdownItem';
import { PageRoutes } from '../../../enums/enums';
import { updateStateAttribute, updateStateResponseStatefulAttribute, useStateExtended } from '../../../helpers/helper';
import { useAppSelector } from '../../../hooks/hooks';
import { type IAugmentedBackendResponse } from '../../../models/IAugmentedBackendResponse';
import type { IRequestParams } from '../../../models/IRequestParams';
import type { IResponseStateful } from '../../../models/IResponseStateful';
import { type ISortingMeta } from '../../../models/ISortingMeta';
import { type TPandasTable } from '../../../models/TPandasTable';
import { SvFactsheetLinksServices } from '../../../services/SvFactsheetLinksServices';

interface State {
  snapshot: IResponseStateful<IAugmentedBackendResponse<TPandasTable>> & IRequestParams;
}

export default function PFactsheetLinkIndex() {
  const { locale } = useAppSelector((state) => state.dashboard);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [state, setState, getState] = useStateExtended<State>({
    snapshot: {
      state: { isLoading: true, isError: false },
      params: { sorting: { sortCol: 4, ascending: 'descending' } }
    }
  });

  const loadFactsheetLinks = async (page: number, sorting?: ISortingMeta, search?: string) => {
    await updateStateResponseStatefulAttribute<State, typeof state.snapshot.data>(
      { snapshot: (await getState()).snapshot },
      setState,
      async () =>
        await SvFactsheetLinksServices.getFactsheetLinksSnapshot({
          page,
          sorting: sorting ?? { sortCol: 4, ascending: 'descending' },
          search,
          locale: locale ?? 'de-DE'
        })
    );
    updateStateAttribute<State>(
      { snapshot: { ...(await getState()).snapshot, params: { sorting, search } } },
      setState
    );
  };

  useEffect(() => {
    (async () => {
      await loadFactsheetLinks(
        Number.parseInt(searchParams.get('page') ?? '1'),
        {
          sortCol: Number.parseInt(searchParams.get('sortCol') ?? '4'),
          ascending: (searchParams.get('ascending') as unknown as ISortingMeta['ascending']) ?? 'descending'
        },
        searchParams.get('search') ?? undefined
      );
    })();
  }, [locale]);

  return (
    <Page title={'Links'}>
      <CPageModule>
        <CKebabBar
          dropdownItems={
            <>
              <XDDropdownItem
                onClick={() => {
                  navigate(`../${PageRoutes.PUBLISHING_FS_NEW_LINK}`);
                }}
              >
                <AddLink />
                <CLocalizedText dictKey={'pubFactsheetCreateButton'} />
              </XDDropdownItem>
            </>
          }
        >
          <h1>
            <CLocalizedText dictKey={'pubFslinkAllH1'} />
          </h1>
        </CKebabBar>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CStatefulContainer submodule apiRequestState={state.snapshot.state} showContent={!!state.snapshot.data}>
          <CPageModulePaginatedTable
            state={state.snapshot.state}
            data={state.snapshot.data}
            params={state.snapshot.params}
            searchBarName={'factsheetLinks'}
            searchBarPlaceholder={CLocalizedTextString('pubFactsheetSearchPlaceholder')}
            colSpanArr={[1, 2, 3, 1, 1]}
            colFormatArr={[undefined, undefined, undefined, undefined, { in: 'tzTimestamp', out: 'localeDateString' }]}
            colAlignmentArr={['left', 'left', 'left', 'right', 'right']}
            onChange={async (page, sorting, search) => {
              await loadFactsheetLinks(page, sorting, search);
              searchParams.set('page', page.toString());
              searchParams.set('sortCol', sorting.sortCol.toString());
              searchParams.set('ascending', sorting.ascending);
              searchParams.set('search', search);
              setSearchParams(searchParams);
            }}
            rowLinks={Object.values(state.snapshot.data?.results?.ID ?? []).map((e) => `${e}`)}
          />
        </CStatefulContainer>
      </CPageModule>
    </Page>
  );
}

import { Close } from '@mui/icons-material';
import { Modal, type ModalProps } from '@mui/material';
import React, { type CSSProperties } from 'react';

import HGeneric from '../helpers/HGeneric';
import XDFloatIn from './animate/XDFloatIn';
import XDButton from './buttons/XDButton';

interface bareModalProps extends Pick<ModalProps, 'open'> {
  onClose: () => void;
  children?: React.ReactNode;
  style?: CSSProperties;
}

export default function CModal(props: bareModalProps) {
  return (
    <Modal
      open={props.open}
      onClick={props.onClose}
      style={{ outline: 0 }}
      disableAutoFocus
      sx={{
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }
      }}
    >
      <div
        className={
          'w-full h-full flex flex-col justify-center items-center p-4 md:p-16 bg-black bg-opacity-20 backdrop-blur'
        }
      >
        <XDFloatIn>
          <div className={'overflow-hidden rounded-xl relative'}>
            <div
              className={'bg-white shadow-lg max-h-[calc(100vh-8rem)] lg:max-h-[calc(100vh-10rem)] overflow-y-auto'}
              style={{
                maxWidth: Math.min(1000, window.innerWidth - 2 * HGeneric.getRemInPx())
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <XDButton
                className={
                  'bg-gray-100 text-2xl bg-opacity-20 backdrop-blur -mr-4 z-10 absolute top-4 right-8 sm:top-8 sm:right-16'
                }
                style={{ borderRadius: '100%' }}
                onClick={props.onClose}
              >
                <Close />
              </XDButton>
              <div className={'p-4 sm:p-12 pt-16 sm:pt-24'} style={{ ...props.style }}>
                {props.children}
              </div>
            </div>
          </div>
        </XDFloatIn>
      </div>
    </Modal>
  );
}

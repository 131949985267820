import React from 'react';

import { BreakpointsInPx } from '../enums/BreakpointsInPx';
import { useAppSelector } from '../hooks/hooks';

interface XDResponsiveGridWrapperProps {
  children?: React.ReactNode;
  maxColumns: 1 | 2 | 3 | 4 | 5;
  style?: Partial<{ rowGap: '1 rem' | '1.5rem'; columnGap: '1 rem' | '1.5rem' }>;
}

export default function CResponsiveGrid(props: XDResponsiveGridWrapperProps) {
  const { dashboardOutletDimensions } = useAppSelector((state) => state.dashboard);

  const columnSpan =
    dashboardOutletDimensions.w <= BreakpointsInPx.BREAKPOINT_1
      ? 1
      : dashboardOutletDimensions.w <= BreakpointsInPx.BREAKPOINT_2
        ? Math.min(1, props.maxColumns)
        : dashboardOutletDimensions.w <= BreakpointsInPx.BREAKPOINT_3
          ? Math.min(2, props.maxColumns)
          : dashboardOutletDimensions.w <= BreakpointsInPx.BREAKPOINT_4
            ? Math.min(4, props.maxColumns)
            : Math.min(5, props.maxColumns);

  return (
    <div
      className={'overflow-hidden grid align-start auto-cols-fr'}
      style={{
        gridTemplateColumns: `repeat(${columnSpan}, minmax(0, 1fr))`,
        columnGap: props.style?.columnGap ?? '1rem',
        rowGap: props.style?.rowGap ?? '1rem'
      }}
    >
      {props.children}
    </div>
  );
}

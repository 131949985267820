import { ArrowForward } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';

import CLocalizedText from '../CLocalizedText';
import CLocalizedTextString from '../CLocalizedTextString';
import CPageModule from '../CPageModule';
import PaddedButton from '../general/PaddedButton';
import Page from '../layout/Page';

export default function NotFoundModule() {
  return (
    <Page title={CLocalizedTextString('globalTitleNotFound')}>
      <div className={'flex flex-col items-center gap-4 justify-center h-[80vh]'}>
        <CPageModule>
          <h2>
            <CLocalizedText dictKey={'globalErrorNotFound'} />
          </h2>
          <p className={'text-center'}>
            <CLocalizedText dictKey={'globalErrorContactAdmin'} />
          </p>
        </CPageModule>
        <CPageModule style={{ paddingTop: 0 }}>
          <Link to={'/'} replace>
            <PaddedButton
              label={<CLocalizedText dictKey={'globalErrorReturnToHomepage'} />}
              icon={<ArrowForward />}
              trailingIcon
            />
          </Link>
        </CPageModule>
      </div>
    </Page>
  );
}

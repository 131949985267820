import React from 'react';

interface props {
  label: string | JSX.Element;
  children: React.ReactNode;
}

export default function CTextFieldDisplay(props: props) {
  return (
    <div className={'flex flex-col'}>
      <p className={'text-xs font-black uppercase opacity-80'}>{props.label}</p>
      <div className={'text-base'}>{props.children === '' ? '-' : props.children}</div>
    </div>
  );
}

import { type IAugmentedBackendResponse } from '../models/IAugmentedBackendResponse';
import { type ISortingMeta } from '../models/ISortingMeta';
import { type TLocales } from '../models/TLocales';
import { type TPandasTable } from '../models/TPandasTable';
import ApiService from './apiService';

export class SvFactsheetLinksServices extends ApiService {
  static async getFactsheetLinksSnapshot(params: {
    page: number;
    sorting: ISortingMeta;
    search?: string;
    locale: TLocales;
  }) {
    return await super.get<IAugmentedBackendResponse<TPandasTable>>('fs', 'links', {
      page: params.page,
      sortCol: params.sorting.sortCol,
      ascending: params.sorting.ascending,
      search: params.search ?? '',
      locale: params.locale
    });
  }
}

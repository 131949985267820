import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'authentication',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  initialState: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    isAdmin: false,
    isUser: false,
    isStaff: false
  } as {
    id: string | undefined;
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    isAdmin: boolean | undefined;
    isUser: boolean | undefined;
    isStaff: false;
  },
  reducers: {
    init: (state, action) => {
      state.firstName = action.payload.account.first_name;
      state.lastName = action.payload.account.last_name;
    },
    updateUserObject: (state, action) => {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.id = action.payload.id;
      state.isAdmin = action.payload.isAdmin;
      state.isUser = action.payload.isUser;
      state.isStaff = action.payload.isStaff;
    }
  }
});

export const { init, updateUserObject } = userSlice.actions;
export default userSlice.reducer;

import { ArrowTopRightOnSquareIcon, LinkIcon } from '@heroicons/react/24/outline';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CResponsiveGrid from '../../components/CResponsiveGrid';
import ActionLinkButton from '../../components/dashboardContent/ActionLinkButton';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateState } from '../../helpers/helper';
import type { IRequestState } from '../../models/IRequestState';
import NewsService from '../../services/newsService';

interface State {
  newsURI?: string;
  newsURIState: IRequestState;
}

export default function PublishingIndex() {
  const [state, setState] = useState<State>({
    newsURIState: { isLoading: true, isError: false }
  });

  useEffect(() => {
    (async () => {
      try {
        updateState<State>({ newsURIState: { isLoading: true, isError: false } }, state, setState);
        const uri = await NewsService.getNewsURI();
        updateState<State>({ newsURI: uri.uri, newsURIState: { isLoading: false, isError: false } }, state, setState);
      } catch (e) {
        updateState<State>({ newsURIState: { isLoading: false, isError: true } }, state, setState);
      }
    })();
  }, []);

  return (
    <Page>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'pubInternalPublications'} />
        </h2>
      </CPageModule>
      <CPageModule style={{ paddingTop: 0 }}>
        <CResponsiveGrid maxColumns={4}>
          <ActionLinkButton
            to={`${PageRoutes.PUBLISHING_FS_OUTLET}/${PageRoutes.PUBLISHING_FS_OVERVIEW_OUTLET}`}
            label={'Factsheet Links'}
          >
            <LinkIcon />
          </ActionLinkButton>
        </CResponsiveGrid>
      </CPageModule>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'pubNewsAndArticles'} />
        </h2>
      </CPageModule>
      <CPageModule style={{ paddingTop: 0 }}>
        {state.newsURIState.isError && <Alert severity={'error'}>Failed to connect to Ghost server</Alert>}
        <CResponsiveGrid maxColumns={4}>
          {state.newsURI && (
            <ActionLinkButton label={'Ghost Console'} href={`${state.newsURI}/ghost`} target={'_blank'}>
              <ArrowTopRightOnSquareIcon />
            </ActionLinkButton>
          )}
        </CResponsiveGrid>
      </CPageModule>
    </Page>
  );
}

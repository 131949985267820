import React from 'react';

import CLocalizedText from './CLocalizedText';
import CPageModule from './CPageModule';

export default function CFactsheetDisclaimer() {
  return (
    <>
      <CPageModule background={<div className={'bg-teal-ppt-3 bg-opacity-20'} />}>
        <div className={'text-xs text-teal-comp-2 flex flex-col gap-6'}>
          <div className={'flex flex-col gap-2'}>
            <h3>
              <CLocalizedText dictKey={'globalDisclaimerH1'} />
            </h3>
          </div>
          <div className={'flex flex-col'}>
            <b className={'text-sm'}>
              <CLocalizedText dictKey={'globalDisclaimerP1H1'} />
            </b>
            <br />
            <CLocalizedText dictKey={'globalDisclaimerP1Desc'} />
          </div>

          <div className={'flex flex-col'}>
            <b className={'text-sm'}>
              <CLocalizedText dictKey={'globalDisclaimerP2H1'} />
            </b>
            <br />
            <CLocalizedText dictKey={'globalDisclaimerP2HDesc'} />
          </div>
        </div>
      </CPageModule>
    </>
  );
}

import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import CScrollToTop from './components/CScrollToTop';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalSnackbar from './components/GlobalSnackbar';
import LoadAndRefresh from './components/LoadAndRefresh';
import ProtectedRoute from './components/PageGates/AuthRequiredRoute';
import RoleRequiredRoute from './components/PageGates/RoleRequiredRoute';
import RouteFactsheet from './components/RouteFactsheet';
import GlobalLoaderMask from './components/general/GlobalLoaderMask';
import XDOutlet from './components/routing/XDOutlet';
import { EUserRoles } from './enums/EUserRoles';
import { PageRoutes } from './enums/enums';
import { useAppDispatch, useAppSelector } from './hooks/hooks';
import { type TLocales } from './models/TLocales';
import Dashboard from './pages/Dashboard';
import PageNotFound from './pages/PageNotFound';
import BacktestEntry from './pages/backtest/BacktestEntry';
import BacktestIndex from './pages/backtest/BacktestIndex';
import BacktestOutlet from './pages/backtest/BacktestOutlet';
import ArticlesEntry from './pages/news/articles/ArticlesEntry';
import ArticlesIndex from './pages/news/articles/ArticlesIndex';
import POpsNewsIndex from './pages/opsNews/POpsNewsIndex';
import PDelta from './pages/publicFactsheet/PDelta';
import PESG from './pages/publicFactsheet/PESG';
import PFactorExposure from './pages/publicFactsheet/PFactorExposure';
import PFactsheetIndex from './pages/publicFactsheet/PFactsheetIndex';
import PFactsheetOutlet from './pages/publicFactsheet/PFactsheetOutlet';
import PPerformance from './pages/publicFactsheet/PPerformance';
import PPerformanceAttribution from './pages/publicFactsheet/PPerformanceAttribution';
import PSectorExposure from './pages/publicFactsheet/PSectorExposure';
import PublicFactsheetIndex from './pages/publicFactsheet/PublicFactsheetIndex';
import PublishingIndex from './pages/publishing/PublishingIndex';
import CreateNewFactsheetLink from './pages/publishing/factsheetManagement/CreateNewFactsheetLink';
import FactsheetManagementEntry from './pages/publishing/factsheetManagement/FactsheetManagementEntry';
import FactsheetManagementIndex from './pages/publishing/factsheetManagement/FactsheetManagementIndex';
import PFactsheetLinkIndex from './pages/publishing/factsheetManagement/PFactsheetLinkIndex';
import PSingleStockEntry from './pages/singleStock/PSingleStockEntry';
import PSingleStockIndex from './pages/singleStock/PSingleStockIndex';
import CreateUser from './pages/userManagement/CreateUser';
import EditUserDetail from './pages/userManagement/EditUserDetail';
import OverviewOutlet from './pages/userManagement/OverviewOutlet';
import UserManagementIndex from './pages/userManagement/UserManagementIndex';
import UserManagementOutlet from './pages/userManagement/UserManagementOutlet';
import UserSecurityAndPrivacy from './pages/userManagement/UserSecurityAndPrivacy';
import UsersOverview from './pages/userManagement/UsersOverview';
import BacktestManagement from './pages/userManagement/userHub/BacktestManagement';
import SingleUserIndex from './pages/userManagement/userHub/UserHubIndex';
import UserHubOutlet from './pages/userManagement/userHub/UserHubOutlet';
import I18nService from './services/i18nService';
import {
  updateFactsheetId,
  updateIsTouchscreen,
  updateLocale,
  updateLocaleDict,
  updateSnackbar
} from './store/appSlice';

const protectedRoutes = [
  // PageRoutes.NEWS_OUTLET,
  PageRoutes.SINGLE_STOCK_OUTLET,
  PageRoutes.BACKTEST_OUTLET,
  PageRoutes.PUBLISHING_OUTLET,
  PageRoutes.USERS_OUTLET,
  PageRoutes.OPS_NEWS_OUTLET
].map((e) => e.toString());

const factsheetRoutes = [PageRoutes.NEWS_OUTLET].map((e) => e.toString());

function App() {
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { processLoading, snackbar, factsheetId, locale, localeDict } = useAppSelector((state) => state.dashboard);

  axios.interceptors.request.use(
    (config) => {
      // Use the JWT from local storage because the one from the slice is often stale
      if (keycloak.authenticated) {
        // Only Update header when there is a JWT in the authentication slice
        config.headers.set('Authorization', `Bearer ${keycloak.token}`);
      }
      return config;
    },
    async (error) => {
      return await Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (r) => {
      return r;
    },
    async (j) => {
      return j;
    }
  );

  useEffect(() => {
    const refreshConfig = () => {
      (async () => {
        const cache = localStorage.getItem('kc-config');
        const res = await axios.get('/api/keycloak');
        if (res.data && cache !== JSON.stringify(res.data)) {
          localStorage.setItem('kc-config', JSON.stringify(res.data));
          window.location.reload();
        }
      })();
    };

    const handleVisibilityChange = async () => {
      try {
        if (keycloak.authenticated) {
          await keycloak.updateToken();
        }
      } catch (e) {
        dispatch(
          updateSnackbar({
            message: 'You have been logged out due to inactivity, please refresh the page to log in again',
            severity: 'error'
          })
        );
      }
    };

    if (window.matchMedia('(any-pointer: coarse)').matches) {
      dispatch(updateIsTouchscreen({ isTouchscreen: true }));
    }

    window.setInterval(handleVisibilityChange, 4 * 60 * 1000);

    refreshConfig();

    dispatch(updateLocale({ locale: (window.localStorage.getItem('locale') as unknown as TLocales) ?? 'de-DE' }));
    dispatch(updateFactsheetId({ factsheetId: window.localStorage.getItem('factsheet-id') ?? undefined }));

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    I18nService.getLocaleDict(locale ?? 'de-DE').then((dict) => dispatch(updateLocaleDict({ localeDict: dict })));
  }, [locale]);

  const requireAuth = (route: JSX.Element) => <ProtectedRoute>{route}</ProtectedRoute>;
  const requireFactsheetLink = (route: JSX.Element) => <RouteFactsheet>{route}</RouteFactsheet>;
  const requireAdminRole = (route: JSX.Element) => (
    <RoleRequiredRoute requiredRoles={[EUserRoles.ADMIN]}>{route}</RoleRequiredRoute>
  );
  const requireStaffRole = (route: JSX.Element) => (
    <RoleRequiredRoute requiredRoles={[EUserRoles.STAFF]}>{route}</RoleRequiredRoute>
  );

  if (!initialized) {
    return <></>;
  }

  return (
    <ErrorBoundary>
      <CScrollToTop />
      <GlobalLoaderMask isVisible={processLoading > 0} />
      <GlobalSnackbar isVisible={snackbar.isVisible} />
      {protectedRoutes.includes(location.pathname.split('/')[1]) ? (
        initialized && keycloak.authenticated ? (
          <></>
        ) : (
          <LoadAndRefresh />
        )
      ) : (
        <></>
      )}
      {factsheetRoutes.includes(location.pathname.split('/')[1]) && !keycloak.authenticated && !factsheetId ? (
        <LoadAndRefresh />
      ) : (
        <></>
      )}
      <div
      // style={{
      //   opacity: protectedRoutes.includes(location.pathname.split('/')[1])
      //     ? keycloak.authenticated
      //       ? 1
      //       : 0
      //     : keycloak.authenticated
      //       ? 1
      //       : !protectedRoutes.includes(location.pathname.split('/')[1])
      //         ? 1
      //         : factsheetId
      //           ? 1
      //           : 0
      // }}
      >
        <Routes>
          <Route path={PageRoutes.DASHBOARD_OUTLET} element={<Dashboard />}>
            <Route path={''} element={<Navigate to={PageRoutes.NEWS_OUTLET} replace />} />
            <Route
              path={PageRoutes.DASHBOARD_HOME}
              element={requireAuth(<Navigate to={PageRoutes.NEWS_OUTLET} replace />)}
            />
            <Route
              path={PageRoutes.NEWS_OUTLET}
              element={requireFactsheetLink(<XDOutlet slug={PageRoutes.NEWS_OUTLET} title={'News'} />)}
            >
              <Route path={PageRoutes.NEWS_HOME} element={<ArticlesIndex />} />
              <Route
                path={PageRoutes.NEWS_ARTICLES_OUTLET}
                element={<XDOutlet slug={PageRoutes.NEWS_ARTICLES_OUTLET} title={localeDict?.newsArticles ?? ''} />}
              >
                <Route path={PageRoutes.NEWS_ARTICLES_HOME} element={<ArticlesIndex />} />
                {/* <Route */}
                {/*   path={PageRoutes.NEWS_ARTICLES_HOME} */}
                {/*   element={<Navigate to={`/${PageRoutes.DASHBOARD_OUTLET}/${PageRoutes.NEWS_OUTLET}`} replace />} */}
                {/* /> */}
                <Route path={':slug'} element={<ArticlesEntry />} />
              </Route>
            </Route>
            <Route
              path={PageRoutes.SINGLE_STOCK_OUTLET}
              element={requireAuth(<XDOutlet slug={PageRoutes.SINGLE_STOCK_OUTLET} title={'Single Stock'} />)}
            >
              <Route path={PageRoutes.SINGLE_STOCK_INDEX} element={<PSingleStockIndex />} />
              <Route path={':identifier'} element={<PSingleStockEntry />} />
            </Route>
            <Route path={PageRoutes.BACKTEST_OUTLET} element={requireAuth(<BacktestOutlet />)}>
              <Route path={PageRoutes.BACKTEST_HOME} element={<BacktestIndex />} />
              <Route path={':allocation'} element={<BacktestIndex />} />
              <Route path={':allocation/:structure'} element={<BacktestIndex />} />
              <Route path={':allocation/:structure/:style'} element={<BacktestIndex />} />
              <Route path={':allocation/:structure/:style/:weight'} element={<BacktestIndex />} />
              <Route path={':allocation/:structure/:style/:weight/:backtestId'} element={<BacktestEntry />} />
            </Route>
            <Route
              path={PageRoutes.PUBLIC_FACTSHEET_COLLECTION_OUTLET}
              element={<XDOutlet slug={PageRoutes.PUBLIC_FACTSHEET_COLLECTION_OUTLET} title={''} />}
            >
              <Route path={PageRoutes.PUBLIC_FACTSHEET_INDEX} element={<PublicFactsheetIndex />} />
              <Route path={':factsheetId'} element={<PFactsheetOutlet />}>
                <Route path={PageRoutes.PUBLIC_FACTSHEET_INDEX} element={<PFactsheetIndex />} />
                <Route path={PageRoutes.PUBLIC_FACTSHEET_FACTOR_EXPOSURE} element={<PFactorExposure />} />
                <Route path={PageRoutes.PUBLIC_FACTSHEET_SECTOR_EXPOSURE} element={<PSectorExposure />} />
                <Route
                  path={PageRoutes.PUBLIC_FACTSHEET_PERFORMANCE_ATTRIBUTION}
                  element={<PPerformanceAttribution />}
                />
                <Route path={PageRoutes.PUBLIC_FACTSHEET_ESG} element={<PESG />} />
                <Route path={PageRoutes.PUBLIC_FACTSHEET_DELTA} element={<PDelta />} />
                <Route path={PageRoutes.PUBLIC_FACTSHEET_PERFORMANCE} element={<PPerformance />} />
              </Route>
            </Route>
            <Route
              path={PageRoutes.OPS_NEWS_OUTLET}
              element={requireAuth(
                requireStaffRole(
                  <XDOutlet
                    slug={PageRoutes.OPS_NEWS_OUTLET}
                    title={localeDict?.globalSidebarOpsNews ?? 'Portfolio Management'}
                  />
                )
              )}
            >
              <Route path={PageRoutes.OPS_NEWS_INDEX} element={<POpsNewsIndex />} />
            </Route>
            <Route
              path={PageRoutes.PUBLISHING_OUTLET}
              element={requireAuth(
                requireStaffRole(
                  <XDOutlet
                    slug={PageRoutes.PUBLISHING_OUTLET}
                    title={localeDict?.globalSidebarPublishing ?? 'Publications'}
                  />
                )
              )}
            >
              <Route path={PageRoutes.PUBLISHING_HOME} element={<PublishingIndex />} />
              <Route
                path={PageRoutes.PUBLISHING_FS_OUTLET}
                element={<XDOutlet slug={PageRoutes.PUBLISHING_FS_OUTLET} title={'Factsheet Links'} />}
              >
                <Route path={PageRoutes.PUBLISHING_FS_OVERVIEW_INDEX} element={<FactsheetManagementIndex />} />
                <Route path={PageRoutes.PUBLISHING_FS_NEW_LINK} element={<CreateNewFactsheetLink />} />
                <Route
                  path={PageRoutes.PUBLISHING_FS_OVERVIEW_OUTLET}
                  element={<XDOutlet slug={PageRoutes.PUBLISHING_FS_OVERVIEW_OUTLET} title={'Links'} />}
                >
                  <Route path={PageRoutes.PUBLISHING_FS_OVERVIEW_INDEX} element={<PFactsheetLinkIndex />} />
                  <Route path={':factsheetId'} element={<FactsheetManagementEntry />} />
                </Route>
              </Route>
            </Route>
            <Route path={PageRoutes.USERS_OUTLET} element={requireAuth(requireAdminRole(<UserManagementOutlet />))}>
              <Route path={PageRoutes.USERS_HOME} element={<UserManagementIndex />} />
              <Route path={PageRoutes.USERS_OVERVIEW_OUTLET} element={<OverviewOutlet />}>
                <Route path={PageRoutes.USERS_OVERVIEW_INDEX} element={<UsersOverview />} />
                <Route path={PageRoutes.USERS_USER_HUB_OUTLET} element={<UserHubOutlet />}>
                  <Route path={PageRoutes.USERS_USER_HUB_INDEX} element={<SingleUserIndex />} />
                  <Route path={PageRoutes.USERS_EDIT} element={<EditUserDetail />} />
                  <Route path={PageRoutes.USERS_DELETE} element={<UserSecurityAndPrivacy />} />
                  <Route path={PageRoutes.USERS_BACKTEST} element={<BacktestManagement />} />
                </Route>
              </Route>
              <Route path={PageRoutes.USERS_NEW} element={<CreateUser />} />
            </Route>
          </Route>
          <Route path={PageRoutes.PAGE_NOT_FOUND} element={<PageNotFound />} />
        </Routes>
      </div>
    </ErrorBoundary>
  );
}

export default App;

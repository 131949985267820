import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Alert, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { type CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import HGeneric from '../helpers/HGeneric';
import { type ISortingMeta } from '../models/ISortingMeta';
import { type ITextFormats } from '../models/ITextFormats';
import CLocalizedText from './CLocalizedText';

export interface CPandasTableProps {
  data?: Record<string, Record<string, any>>;
  showIndex?: boolean;
  style?: Partial<{
    minWidth: number | 'auto';
    width: '100%';
  }>;
  colSpanArr?: number[];
  colAlignmentArr?: Array<CSSProperties['textAlign']>;
  sorting?: ISortingMeta;
  onSortChange?: (sortCol: ISortingMeta['sortCol'], ascending: ISortingMeta['ascending']) => void;
  cellHeight?: CSSProperties['height'];
  colFormatArr?: Array<{ in: ITextFormats; out: ITextFormats } | undefined>;
  onRowClick?: (rowIndex: number) => void;
  rowLinks?: string[];
}

export default function CPandasTable(props: CPandasTableProps) {
  if (!props.data) return <></>;

  const entries = Object.values(Object.values(props.data ?? {})[0] ?? {});

  if (entries.length === 0)
    return (
      // <p className={'text-center py-4 rounded-lg'}>
      //   <CLocalizedText dictKey={'errorEmptyList'} />
      // </p>
      <Alert severity={'info'}>
        <CLocalizedText dictKey={'errorEmptyList'} />
      </Alert>
    );

  const minWidth = props.style?.minWidth ?? 970;
  const columnCount = Object.keys(props.data).length + (props.showIndex ? 1 : 0);
  const colRatioArr = [...(props.colSpanArr ?? [])?.slice(0, columnCount)];

  const lenDelta = columnCount - colRatioArr.length;

  for (let x = 0; x < lenDelta; x++) {
    colRatioArr.push(props.colSpanArr?.[0] ?? 1);
  }

  const spanArrSum = colRatioArr.reduce((total, num) => {
    return total + num;
  });

  const colPixelArr = colRatioArr.map((e) => {
    return props.colSpanArr ? `${Math.round((e / spanArrSum) * (minWidth === 'auto' ? 970 : minWidth))}px` : 'auto';
  });

  return (
    <div style={{ width: props.style?.width }} className={'overflow-hidden'}>
      <Table style={{ minWidth, ...props.style }} size={'small'}>
        <TableHead>
          <TableRow style={{ borderBottom: '2px solid' }}>
            {props.showIndex && <TableCell style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} />}
            {Object.keys(props.data ?? {}).map((column, ix) => (
              <TableCell
                key={column}
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  padding: '0.75rem 0.5rem',
                  width: colPixelArr[ix + (props.showIndex ? 1 : 0)],
                  maxWidth: colPixelArr[ix + (props.showIndex ? 1 : 0)],
                  display: props.colSpanArr?.[ix + (props.showIndex ? 1 : 0)] === 0 ? 'none' : 'auto',
                  cursor: props.sorting ? 'pointer' : 'auto',
                  wordBreak: 'break-word',
                  hyphens: 'auto'
                  // color: props.onSortChange ? '#ff8945' : 'auto'
                }}
                onClick={() => {
                  if (props.onSortChange && props.sorting) {
                    props.onSortChange(
                      ix,
                      props.sorting.sortCol === ix
                        ? props.sorting.ascending === 'ascending'
                          ? 'descending'
                          : 'ascending'
                        : props.sorting.ascending
                    );
                  }
                }}
                className={props.onSortChange ? 'text-teal-ppt-1 hover:text-teal' : ''}
              >
                <div className={'font-bold text-center'}>
                  <span>{column}</span>{' '}
                  <span className={'text-base'} style={{ color: 'inherit !important' }}>
                    <SortIcon
                      activeColNumber={props.sorting?.sortCol}
                      ascending={props.sorting?.ascending}
                      colNumber={ix}
                    />
                  </span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((row, rowNumber) => (
            <TableRow
              key={rowNumber}
              onClick={() => props.onRowClick?.(rowNumber)}
              style={{
                cursor: props.onRowClick ?? props.rowLinks?.[rowNumber] ? 'pointer' : 'default'
              }}
              className={
                props.onRowClick ?? props.rowLinks?.[rowNumber]
                  ? 'hover:bg-teal hover:bg-opacity-10 hover:text-teal'
                  : ''
              }
            >
              {props.showIndex && (
                <TableCell
                  style={{
                    borderBottom: 'none',
                    width: colPixelArr[0],
                    maxWidth: colPixelArr[0],
                    minHeight: props.cellHeight ?? '3.5rem',
                    height: props.cellHeight ?? '3.5rem',
                    color: 'inherit'
                  }}
                >
                  {props.rowLinks?.[rowNumber] ? (
                    <Link
                      className={
                        'py-0.5 w-full h-full text-inherit font-normal flex flex-col items-stretch justify-center'
                      }
                      to={props.rowLinks[rowNumber]}
                    >
                      <span>{Object.keys(Object.values(props.data ?? {})[0] ?? {})[rowNumber]}</span>
                    </Link>
                  ) : (
                    <span className={'py-0.5 w-full h-full flex flex-col items-stretch justify-center'}>
                      {Object.keys(Object.values(props.data ?? {})[0] ?? {})[rowNumber]}{' '}
                    </span>
                  )}
                </TableCell>
              )}
              {Object.keys(props.data ?? {}).map((column, colNumber) => {
                const cellValue = Object.values(props.data?.[column] ?? {})[rowNumber];
                return (
                  <TableCell
                    key={`${rowNumber}_${colNumber}_${row}`}
                    style={{
                      textAlign: props.colAlignmentArr?.[colNumber + (props.showIndex ? 1 : 0)] ?? 'inherit',
                      backgroundColor: 'rgba(0, 0, 0, 0) !important',
                      borderBottom: 'none',
                      width: colPixelArr[colNumber + (props.showIndex ? 1 : 0)],
                      maxWidth: colPixelArr[colNumber + (props.showIndex ? 1 : 0)],
                      minHeight: props.cellHeight ?? '3.5rem',
                      height: props.cellHeight ?? '3.5rem',
                      color: 'inherit',
                      display: props.colSpanArr?.[colNumber + (props.showIndex ? 1 : 0)] === 0 ? 'none' : 'auto'
                    }}
                  >
                    {props.rowLinks?.[rowNumber] ? (
                      <Link
                        className={
                          'w-full h-full py-0.5 text-inherit font-normal flex flex-col items-stretch justify-center'
                        }
                        to={props.rowLinks[rowNumber]}
                      >
                        <span>
                          {HGeneric.formatString(
                            cellValue,
                            props.colFormatArr?.[colNumber + (props.showIndex ? 1 : 0)]
                          )}
                        </span>
                      </Link>
                    ) : (
                      <span className={'py-0.5 w-full h-full flex flex-col items-stretch justify-center'}>
                        {HGeneric.formatString(cellValue, props.colFormatArr?.[colNumber + (props.showIndex ? 1 : 0)])}
                      </span>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function SortIcon(props: {
  colNumber: number;
  activeColNumber?: ISortingMeta['sortCol'];
  ascending?: ISortingMeta['ascending'];
}) {
  if (props.colNumber !== props.activeColNumber) return <></>;
  if (props.activeColNumber === undefined) return <></>;
  if (props.ascending === 'descending') return <ArrowUpward />;
  else return <ArrowDownward />;
}

import React, { useEffect } from 'react';

import CHorizontalScrollContainer from '../../components/CHorizontalScrollContainer';
import CLocalizedText from '../../components/CLocalizedText';
import CLocalizedTextString from '../../components/CLocalizedTextString';
import CPageModule from '../../components/CPageModule';
import CPageModulePaginatedTable from '../../components/CPageModulePaginatedTable';
import CPandasTable from '../../components/CPandasTable';
import CStatefulContainer from '../../components/CStatefulContainer';
import Page from '../../components/layout/Page';
import { updateStateAttribute, updateStateResponseStatefulAttribute, useStateExtended } from '../../helpers/helper';
import { type IAugmentedBackendResponse } from '../../models/IAugmentedBackendResponse';
import { type IRequestParams } from '../../models/IRequestParams';
import { type IResponseStateful } from '../../models/IResponseStateful';
import { type IOpsNewsLatest } from '../../models/OpsNewsResponse';
import { type TPandasTable } from '../../models/TPandasTable';
import SvOpsNewsServices from '../../services/SvOpsNewsServices';

interface State {
  latest: IResponseStateful<IOpsNewsLatest>;
  mergers: IResponseStateful<IAugmentedBackendResponse<TPandasTable>> & IRequestParams;
  dividends: IResponseStateful<IAugmentedBackendResponse<TPandasTable>> & IRequestParams;
}

export default function POpsNewsIndex() {
  const [state, setState, getState] = useStateExtended<State>({
    latest: { state: { isLoading: true, isError: false } },
    mergers: {
      state: { isLoading: true, isError: false },
      params: { sorting: { sortCol: 3, ascending: 'descending' } }
    },
    dividends: {
      state: { isLoading: true, isError: false },
      params: { sorting: { sortCol: 4, ascending: 'ascending' } }
    }
  });

  useEffect(() => {
    (async () => {
      await updateStateResponseStatefulAttribute<State, typeof state.latest.data>(
        { latest: (await getState()).latest },
        setState,
        async () => await SvOpsNewsServices.getLatest()
      );
      const latestMergers = (await getState()).mergers;
      await updateStateResponseStatefulAttribute<State, typeof state.mergers.data>(
        { mergers: latestMergers },
        setState,
        async () =>
          await SvOpsNewsServices.getMergers({
            page: latestMergers.data?.meta.pagination.page ?? 1,
            sorting: {
              sortCol: latestMergers.params?.sorting?.sortCol ?? 4,
              ascending: latestMergers.params?.sorting?.ascending ?? 'ascending'
            },
            search: latestMergers.params?.search ?? ''
          })
      );
      const latestDividends = (await getState()).dividends;
      await updateStateResponseStatefulAttribute<State, typeof state.dividends.data>(
        { dividends: latestDividends },
        setState,
        async () =>
          await SvOpsNewsServices.getDividends({
            page: latestDividends.data?.meta.pagination.page ?? 1,
            sorting: {
              sortCol: latestDividends.params?.sorting?.sortCol ?? 3,
              ascending: latestDividends.params?.sorting?.ascending ?? 'ascending'
            },
            search: latestDividends.params?.search ?? ''
          })
      );
    })();
  }, []);

  return (
    <Page title={CLocalizedTextString('globalSidebarOpsNews')}>
      <CPageModule>
        <h1>
          <CLocalizedText dictKey={'opsP1H1'} />
        </h1>
        <p>
          <CLocalizedText dictKey={'opsP1Desc'} />
        </p>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'opsP2H1'} />
          </h3>
          <p>
            <CLocalizedText dictKey={'opsP2Desc'} />
          </p>
        </CPageModule>
        <CStatefulContainer apiRequestState={state.latest.state} showContent={!!state.latest.data} submodule>
          <CHorizontalScrollContainer>
            <CPandasTable
              data={state.latest.data?.calendar_events}
              colAlignmentArr={['right', 'right', 'right']}
              colSpanArr={[1, 1, 1]}
              colFormatArr={[
                { in: 'tzTimestamp', out: 'localeDateString' },
                { in: 'tzTimestamp', out: 'localeDateString' },
                { in: 'tzTimestamp', out: 'localeDateString' }
              ]}
            />
          </CHorizontalScrollContainer>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'opsP3H1'} />
          </h3>
          <p>
            <CLocalizedText dictKey={'opsP3Desc'} />
          </p>
        </CPageModule>
        <CStatefulContainer apiRequestState={state.latest.state} showContent={!!state.latest.data} submodule>
          <CHorizontalScrollContainer>
            <CPandasTable
              data={state.latest.data?.stock_splits}
              colAlignmentArr={['left', 'left', 'left', 'right']}
              colFormatArr={[
                undefined,
                undefined,
                undefined,
                { in: 'tzTimestamp', out: 'localeDateString' },
                undefined,
                { in: 'boolean', out: 'booleanString' },
                { in: 'boolean', out: 'booleanString' }
              ]}
            />
          </CHorizontalScrollContainer>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'opsP4H1'} />
          </h3>
          <p>
            <CLocalizedText dictKey={'opsP4Desc'} />
          </p>
        </CPageModule>
        <CStatefulContainer apiRequestState={state.mergers.state} showContent={!!state.mergers.data} submodule>
          <CPageModulePaginatedTable
            state={state.mergers.state}
            searchBarPlaceholder={CLocalizedTextString('placeholderCompanyName')}
            data={state.mergers.data}
            colFormatArr={[
              undefined,
              undefined,
              undefined,
              { in: 'tzTimestamp', out: 'localeDateString' },
              undefined,
              { in: 'linkString', out: 'linkHrefBlank' },
              { in: 'boolean', out: 'booleanString' },
              { in: 'boolean', out: 'booleanString' }
            ]}
            colAlignmentArr={['left', 'left', 'left', 'right']}
            colSpanArr={[3, 1.5, 1.5, 1.5, 4.5, 0, 1, 1]}
            searchBarName={'companies'}
            // style={{ minWidth: 1500 }}
            onChange={async (page, sorting, search) => {
              await updateStateResponseStatefulAttribute<State, typeof state.mergers.data>(
                { mergers: (await getState()).mergers },
                setState,
                async () => await SvOpsNewsServices.getMergers({ search: search ?? '', page, sorting })
              );
              updateStateAttribute<State>(
                {
                  mergers: { ...(await getState()).mergers, params: { sorting, search } }
                },
                setState
              );
            }}
            params={state.mergers.params}
            rowLinks={Object.values(state.mergers.data?.results?.link ?? {})}
          />
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'opsP5H1'} />
          </h3>
          <p>
            <CLocalizedText dictKey={'opsP5Desc'} />
          </p>
        </CPageModule>{' '}
        <CStatefulContainer apiRequestState={state.dividends.state} showContent={!!state.dividends.data} submodule>
          <CPageModulePaginatedTable
            state={state.dividends.state}
            data={state.dividends.data}
            searchBarName={'dividends'}
            searchBarPlaceholder={CLocalizedTextString('placeholderCompanyName')}
            colSpanArr={[1.5, 1, 1, 0.8, 0.6, 0.9, 0.9]}
            colAlignmentArr={['left', 'left', 'left', 'left', 'right', 'left', 'left']}
            colFormatArr={[
              undefined,
              undefined,
              undefined,
              undefined,
              { in: 'tzTimestamp', out: 'localeDateString' },
              { in: 'boolean', out: 'booleanString' },
              { in: 'boolean', out: 'booleanString' }
            ]}
            onChange={async (page, sorting, search) => {
              await updateStateResponseStatefulAttribute<State, typeof state.dividends.data>(
                { dividends: (await getState()).dividends },
                setState,
                async () => await SvOpsNewsServices.getDividends({ search: search ?? '', page, sorting })
              );
              updateStateAttribute<State>(
                {
                  dividends: { ...(await getState()).dividends, params: { sorting, search } }
                },
                setState
              );
            }}
            params={state.dividends.params}
          />
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'opsP6H1'} />
          </h3>
          <p>
            <CLocalizedText dictKey={'opsP6Desc'} />
          </p>
        </CPageModule>
        <CStatefulContainer apiRequestState={state.latest.state} showContent={!!state.latest.data} submodule>
          <CHorizontalScrollContainer>
            <CPandasTable data={state.latest.data?.isin_changes} />
          </CHorizontalScrollContainer>
        </CStatefulContainer>
        <CPageModule submodule>
          <p>
            <CLocalizedText dictKey={'opsP6Desc2'} />
          </p>
        </CPageModule>
        <CStatefulContainer apiRequestState={state.latest.state} showContent={!!state.latest.data} submodule>
          <CHorizontalScrollContainer>
            <CPandasTable data={state.latest.data?.tradingitemid_changes} />
          </CHorizontalScrollContainer>
        </CStatefulContainer>
      </CPageModule>
    </Page>
  );
}

import { Check, Close, Factory, Merge, Paid, PieChart, Storefront } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import CLocalizedText from '../../components/CLocalizedText';
import CLocalizedTextString from '../../components/CLocalizedTextString';
import CPageModule from '../../components/CPageModule';
import CSearchBar from '../../components/CSearchBar';
import CSortDropdown from '../../components/CSortDropdown';
import CompanyInfoDetail from '../../components/CompanyInfoDetail';
import XDEmptyResult from '../../components/alerts/XDEmptyResult';
import XDEntryCard from '../../components/buttons/XDEntryCard';
import Page from '../../components/layout/Page';
import XDPaginator from '../../components/layout/XDPaginator';
import XDResponsiveGridWrapper from '../../components/layout/XDResponsiveGridWrapper';
import { type SortingLogic } from '../../enums/sortingLogic';
import { updateState } from '../../helpers/helper';
import { type PaginationMeta } from '../../models/GhostPostModel';
import { type IRequestState } from '../../models/IRequestState';
import { type MSingleStockCardSummary } from '../../models/singleStock/MSingleStockCardSummary';
import SvSingleStockServices from '../../services/SvSingleStockServices';

interface State {
  requestState: IRequestState;
  requestQuery?: Partial<{
    page: number;
    search: string;
    sortBy: SortingLogic;
  }>;
  pagination?: PaginationMeta;
  singleStockList?: MSingleStockCardSummary[];
}

/**
 * Index page for single stock section.
 * @constructor
 */
export default function PSingleStockIndex() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<State>({ requestState: { isError: false, isLoading: false } });

  const handleFetchList = async (page: number, search?: string, sortBy?: SortingLogic) => {
    updateState<State>({ requestState: { ...state.requestState, isLoading: true } }, state, setState);
    const res = await SvSingleStockServices.listSingleStocks(page, search, sortBy);
    updateState<State>(
      {
        requestState: {
          ...state.requestState,
          isLoading: false,
          isError: res.hasError(),
          strError: res.getErrorString()
        }
      },
      state,
      setState
    );

    if (!res.hasError()) {
      updateState<State>(
        {
          singleStockList: res.getData().results,
          pagination: res.getData().meta.pagination,
          requestQuery: { page, search, sortBy }
        },
        state,
        setState
      );
    }
  };

  const handlePageChange = async (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };

  const handleSort = async (sortBy: SortingLogic) => {
    searchParams.set('sortBy', sortBy);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const handleSubmitSearch = async (searchText: string) => {
    searchParams.set('search', searchText);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    (async () => {
      const page = parseInt(searchParams.get('page') ?? '1');
      const search = searchParams.get('search') ?? '';
      const sortBy = (searchParams.get('sortBy') as SortingLogic) ?? 'marketCapDesc';
      await handleFetchList(page, search, sortBy);
    })();
  }, [searchParams]);

  return (
    <Page title={CLocalizedTextString('singleStockSection')}>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'singleStockSection'} />
        </h2>
      </CPageModule>
      <CPageModule style={{ paddingTop: 0 }} apiRequestState={state.requestState} showContent={!!state.singleStockList}>
        <div className={'flex gap-x-4 gap-y-2 flex-wrap items-center'}>
          <div className={'w-full'}>
            <CSearchBar
              onSubmit={handleSubmitSearch}
              placeholder={CLocalizedTextString('singleStockSearchBarPlaceholder')}
            />
          </div>
        </div>
        <div className={'flex justify-end'}>
          <CSortDropdown
            onChange={async (choice) => {
              await handleSort(choice as SortingLogic);
            }}
            options={{
              companyNameAsc: <CLocalizedText dictKey={'globalSortByCompanyNameAsc'} />,
              companyNameDesc: <CLocalizedText dictKey={'globalSortByCompanyNameDesc'} />,
              marketCapAsc: <CLocalizedText dictKey={'globalSortByMarketCapAsc'} />,
              marketCapDesc: <CLocalizedText dictKey={'globalSortByMarketCapDesc'} />
            }}
            defaultKey={'marketCapDesc'}
          />
        </div>
        {state.singleStockList?.length === 0 && <XDEmptyResult />}
        <XDResponsiveGridWrapper widerBox>
          {state.singleStockList?.map((e, ix) => (
            <IndexCompanyCard key={`${e.companyid}-${e.gvkey}-${e.tradingitemid}`} order={ix} attr={e} />
          ))}
        </XDResponsiveGridWrapper>
        <XDPaginator
          pagination={state.pagination}
          retrievePageCallback={async (page) => {
            await handlePageChange(page);
          }}
          apiReqState={state.requestState}
        />
      </CPageModule>
    </Page>
  );
}

interface props {
  attr: MSingleStockCardSummary;
  order?: number;
}

function IndexCompanyCard(props: props) {
  let tradingItemIcon: JSX.Element;

  switch (props.attr.tradingitemstatusname) {
    case 'Active':
      tradingItemIcon = <Check />;
      break;
    case 'Merged':
      tradingItemIcon = <Merge />;
      break;
    default:
      tradingItemIcon = <Close />;
      break;
  }

  return (
    <Link to={props.attr.companyid.toString()}>
      <XDEntryCard order={props.order}>
        <div className={'flex flex-col gap-0'}>
          <p className={'text-sm font-bold text-gray-600'}>{props.attr.tickersymbol}</p>
          <h3>{props.attr.companyname}</h3>
        </div>
        <div className={'flex flex-col gap-2 gap-x-4 text-gray-600 text-sm'}>
          <CompanyInfoDetail desc={props.attr.tradingitemstatusname} icon={tradingItemIcon} title={'Trading status'} />
          {props.attr.formattedmarketcap && (
            <CompanyInfoDetail desc={props.attr.formattedmarketcap} icon={<Paid />} title={'Market capitalization'} />
          )}
          <CompanyInfoDetail desc={props.attr.exchangename} icon={<Storefront />} title={'Exchange'} />
          <CompanyInfoDetail desc={props.attr.gsector} icon={<PieChart />} title={'Sector'} />
          <CompanyInfoDetail desc={props.attr.gind} icon={<Factory />} title={'Industry'} />
        </div>
      </XDEntryCard>
    </Link>
  );
}

import { type IAugmentedBackendResponse } from '../models/IAugmentedBackendResponse';
import { type ISortingMeta } from '../models/ISortingMeta';
import { type IOpsNewsLatest } from '../models/OpsNewsResponse';
import { type TPandasTable } from '../models/TPandasTable';
import ApiService from './apiService';

export default class SvOpsNewsServices extends ApiService {
  static async getLatest() {
    return await super.get<IOpsNewsLatest>('opsnews', 'latest');
  }

  static async getMergers(params: { page: number; search: string; sorting: ISortingMeta }) {
    return await super.get<IAugmentedBackendResponse<TPandasTable>>('opsnews', 'mergers', {
      page: params.page,
      search: params.search,
      sortCol: params.sorting.sortCol,
      ascending: params.sorting.ascending
    });
  }

  static async getDividends(params: { page: number; search: string; sorting: ISortingMeta }) {
    return await super.get<IAugmentedBackendResponse<TPandasTable>>('opsnews', 'dividends', {
      page: params.page,
      search: params.search,
      sortCol: params.sorting.sortCol,
      ascending: params.sorting.ascending
    });
  }
}

import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CFactsheetDisclaimer from '../../components/CFactsheetDisclaimer';
import CHorizontalScrollContainer from '../../components/CHorizontalScrollContainer';
import CLocalizedText from '../../components/CLocalizedText';
import CLocalizedTextString from '../../components/CLocalizedTextString';
import CPageModule from '../../components/CPageModule';
import CPageModulePlot from '../../components/CPageModulePlot';
import CPandasTable from '../../components/CPandasTable';
import CSectionNavigator from '../../components/CSectionNavigator';
import CStatefulContainer from '../../components/CStatefulContainer';
import CTab from '../../components/CTab';
import CTabItem from '../../components/CTabItem';
import GenericDateRangeSelector from '../../components/GenericDateRangeSelector';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateStateAttribute, updateStateResponseStatefulAttribute, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type IResponseStateful } from '../../models/IResponseStateful';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import { type TPandasTable } from '../../models/TPandasTable';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

interface State {
  // overview: IResponseStateful<PlotlyResponse>;
  // performanceComponents: IResponseStateful<PlotlyResponse>;
  stockSelectionChart: IResponseStateful<{ withoutLeverage: PlotlyResponse; withLeverage: PlotlyResponse }>;
  stockSelectionTable: IResponseStateful<{ table: TPandasTable; startDate: string; endDate: string }> & {
    initStartDate: number;
    initEndDate: number;
    minDate: number;
    maxDate: number;
  };
  betaTransformation: IResponseStateful<PlotlyResponse>;
  riskProfileChart: IResponseStateful<PlotlyResponse>;
  riskProfileTable: IResponseStateful<{ table: TPandasTable; startDate: string; endDate: string }> & {
    initStartDate: number;
    initEndDate: number;
    minDate: number;
    maxDate: number;
  };
  breakdowns: IResponseStateful<{ economic: PlotlyResponse; instrumental: PlotlyResponse }>;
}

export default function PPerformanceAttribution() {
  const { factsheetId } = useParams();
  const { locale } = useAppSelector((state) => state.dashboard);
  const [state, setState, getState] = useStateExtended<State>({
    // overview: { state: { isLoading: true, isError: false } },
    // performanceComponents: { state: { isLoading: true, isError: false } }
    stockSelectionChart: { state: { isLoading: true, isError: false } },
    stockSelectionTable: {
      state: { isLoading: true, isError: false },
      initStartDate: 1000 * dayjs('2024-05-22').unix(),
      initEndDate: 1000 * dayjs().unix(),
      minDate: 0,
      maxDate: 0
    },
    betaTransformation: { state: { isLoading: true, isError: false } },
    riskProfileChart: { state: { isLoading: true, isError: false } },
    riskProfileTable: {
      state: { isLoading: true, isError: false },
      initStartDate: 1000 * dayjs('2024-05-22').unix(),
      initEndDate: 1000 * dayjs().unix(),
      minDate: 0,
      maxDate: 0
    },
    breakdowns: { state: { isLoading: true, isError: false } }
  });

  useEffect(() => {
    (async () => {
      const latestState = await getState();
      await updateStateResponseStatefulAttribute<State, typeof state.stockSelectionChart.data>(
        { stockSelectionChart: state.stockSelectionChart },
        setState,
        async () =>
          await PublicFactsheetServices.getPerformanceAttributionStockSelectionChart(factsheetId ?? '', {
            locale: locale ?? 'de-DE'
          })
      );

      await updateStateResponseStatefulAttribute<State, typeof state.stockSelectionTable.data>(
        { stockSelectionTable: state.stockSelectionTable },
        setState,
        async () =>
          await PublicFactsheetServices.getPerformanceAttributionStockSelectionTable(factsheetId ?? '', {
            locale: locale ?? 'de-DE',
            startDate:
              latestState.stockSelectionTable.data?.startDate ??
              dayjs(state.stockSelectionTable.initStartDate).format('YYYY-MM-DD'),
            endDate:
              latestState.stockSelectionTable.data?.endDate ??
              dayjs(state.stockSelectionTable.initEndDate).format('YYYY-MM-DD')
          })
      );

      if (latestState.stockSelectionTable.minDate === 0) {
        const latestState2 = await getState();
        updateStateAttribute<State>(
          {
            stockSelectionTable: {
              ...latestState2.stockSelectionTable,
              minDate: 1000 * dayjs(latestState2.stockSelectionTable.data?.startDate).unix(),
              maxDate: 1000 * dayjs(latestState2.stockSelectionTable.data?.endDate).unix(),
              initStartDate: 1000 * dayjs(latestState2.stockSelectionTable.data?.startDate).unix(),
              initEndDate: 1000 * dayjs(latestState2.stockSelectionTable.data?.endDate).unix()
            }
          },
          setState
        );
      }

      await updateStateResponseStatefulAttribute<State, typeof state.betaTransformation.data>(
        { betaTransformation: state.betaTransformation },
        setState,
        async () =>
          await PublicFactsheetServices.getPerformanceAttributionBetaTransformations(factsheetId ?? '', {
            locale: locale ?? 'de-DE'
          })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.riskProfileChart.data>(
        { riskProfileChart: state.riskProfileChart },
        setState,
        async () =>
          await PublicFactsheetServices.getPerformanceAttributionRiskProfileChart(factsheetId ?? '', {
            locale: locale ?? 'de-DE'
          })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.riskProfileTable.data>(
        { riskProfileTable: state.riskProfileTable },
        setState,
        async () =>
          await PublicFactsheetServices.getPerformanceAttributionRiskProfileTable(factsheetId ?? '', {
            locale: locale ?? 'de-DE',
            startDate:
              latestState.riskProfileTable.data?.startDate ??
              dayjs(state.riskProfileTable.initStartDate).format('YYYY-MM-DD'),
            endDate:
              latestState.riskProfileTable.data?.endDate ??
              dayjs(state.riskProfileTable.initEndDate).format('YYYY-MM-DD')
          })
      );

      if (latestState.riskProfileTable.minDate === 0) {
        const latestState2 = await getState();
        updateStateAttribute<State>(
          {
            riskProfileTable: {
              ...latestState2.riskProfileTable,
              minDate: 1000 * dayjs(latestState2.riskProfileTable.data?.startDate).unix(),
              maxDate: 1000 * dayjs(latestState2.riskProfileTable.data?.endDate).unix(),
              initStartDate: 1000 * dayjs(latestState2.riskProfileTable.data?.startDate).unix(),
              initEndDate: 1000 * dayjs(latestState2.riskProfileTable.data?.endDate).unix()
            }
          },
          setState
        );
      }

      await updateStateResponseStatefulAttribute<State, typeof state.breakdowns.data>(
        { breakdowns: state.breakdowns },
        setState,
        async () =>
          await PublicFactsheetServices.getPerformanceAttributionBreakdowns(factsheetId ?? '', {
            locale: locale ?? 'de-DE'
          })
      );

      // updateStateResponseStatefulAttribute<State, typeof state.overview.data>(
      //   { overview: state.overview },
      //   setState,
      //   async () =>
      //     await PublicFactsheetServices.getPerformanceAttributionOverview(factsheetId ?? '', {
      //       locale: locale ?? 'de-DE'
      //     })
      // );
      // updateStateResponseStatefulAttribute<State, typeof state.performanceComponents.data>(
      //   { performanceComponents: state.performanceComponents },
      //   setState,
      //   async () =>
      //     await PublicFactsheetServices.getPerformanceAttributionPerformanceComponents(factsheetId ?? '', {
      //       locale: locale ?? 'de-DE'
      //     })
      // );
    })();
  }, [locale]);

  const factsheetNavigator = (
    <CSectionNavigator
      previous={{
        label: <CLocalizedText dictKey={'globalFactsheetSectionSecex'} />,
        url: `../${PageRoutes.PUBLIC_FACTSHEET_SECTOR_EXPOSURE}`
      }}
      next={{
        label: <CLocalizedText dictKey={'globalFactsheetSectionDelta'} />,
        url: `../${PageRoutes.PUBLIC_FACTSHEET_DELTA}`
      }}
    />
  );

  return (
    <Page title={CLocalizedTextString('fsovP2Attribution')}>
      <CPageModule style={{ paddingBottom: 0 }}>{factsheetNavigator}</CPageModule>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'fspaP1H1'} />
        </h2>
        <p>
          <CLocalizedText dictKey={'fspaP1Desc'} />
        </p>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>{<CLocalizedText dictKey={'fspaP2H1'} />}</h3>
          <p>{<CLocalizedText dictKey={'fspaP2Desc1'} />}</p>
          <p>
            <CLocalizedText dictKey={'fspaP2Desc2'} />
          </p>
          <ol>
            <li>
              <CLocalizedText dictKey={'fspaP2Desc3'} />
            </li>
          </ol>
          <p>
            <CLocalizedText dictKey={'fspaP2Desc4'} />
          </p>
          <ol start={2}>
            <li>
              <CLocalizedText dictKey={'fspaP2Desc5'} />
            </li>
          </ol>
          <p>
            <CLocalizedText dictKey={'fspaP2Desc6'} />
          </p>
        </CPageModule>
        <CTab submodule>
          <CTabItem label={'withoutLeverage'} text={<CLocalizedText dictKey={'fspaP2Tab1'} />}>
            <CStatefulContainer
              submodule
              apiRequestState={state.stockSelectionChart.state}
              showContent={!!state.stockSelectionChart.data}
            >
              <CPageModulePlot
                data={state.stockSelectionChart.data?.withoutLeverage.data}
                layout={state.stockSelectionChart.data?.withoutLeverage.layout}
              />
            </CStatefulContainer>
          </CTabItem>
          <CTabItem label={'withLeverage'} text={<CLocalizedText dictKey={'fspaP2Tab2'} />}>
            <CStatefulContainer
              submodule
              apiRequestState={state.stockSelectionChart.state}
              showContent={!!state.stockSelectionChart.data}
            >
              <CPageModulePlot
                data={state.stockSelectionChart.data?.withLeverage.data}
                layout={state.stockSelectionChart.data?.withLeverage.layout}
              />
            </CStatefulContainer>
          </CTabItem>
        </CTab>
        <CStatefulContainer
          submodule
          apiRequestState={state.stockSelectionTable.state}
          showContent={!!state.stockSelectionTable.data && state.stockSelectionTable.minDate > 0}
        >
          <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CPageModule submodule>
              <div className={'flex justify-end'}>
                <GenericDateRangeSelector
                  initStartDate={state.stockSelectionTable.initStartDate}
                  initEndDate={state.stockSelectionTable.initEndDate}
                  minDate={state.stockSelectionTable.minDate}
                  maxDate={state.stockSelectionTable.maxDate}
                  onChange={async (startUnix, endUnix) => {
                    await updateStateResponseStatefulAttribute<State, typeof state.stockSelectionTable.data>(
                      { stockSelectionTable: state.stockSelectionTable },
                      setState,
                      async () =>
                        await PublicFactsheetServices.getPerformanceAttributionStockSelectionTable(factsheetId ?? '', {
                          locale: locale ?? 'de-DE',
                          startDate: dayjs(startUnix).format('YYYY-MM-DD'),
                          endDate: dayjs(endUnix).format('YYYY-MM-DD')
                        })
                    );
                  }}
                />
              </div>
            </CPageModule>
            <CHorizontalScrollContainer>
              <CPandasTable data={state.stockSelectionTable.data?.table} colSpanArr={[1, 1, 1, 1, 1, 1]} />
            </CHorizontalScrollContainer>
          </CPageModule>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fspaP3H1'} />
          </h3>
          <p>
            <CLocalizedText dictKey={'fspaP3Desc'} />
          </p>
        </CPageModule>
        <CPageModule submodule>
          <h4>
            <CLocalizedText dictKey={'fspaP4H2'} />
          </h4>
          <p>
            <CLocalizedText dictKey={'fspaP4Des'} />
          </p>
        </CPageModule>
        <CStatefulContainer
          submodule
          apiRequestState={state.betaTransformation.state}
          showContent={!!state.betaTransformation.data}
        >
          <CPageModulePlot data={state.betaTransformation.data?.data} layout={state.betaTransformation.data?.layout} />
        </CStatefulContainer>
        <CPageModule submodule>
          <h4>
            <CLocalizedText dictKey={'fspaP5H2'} />
          </h4>
          <p>
            <CLocalizedText dictKey={'fspaP5Des1'} />
          </p>
        </CPageModule>
        <CStatefulContainer
          submodule
          apiRequestState={state.riskProfileChart.state}
          showContent={!!state.riskProfileChart.data}
        >
          <CPageModulePlot data={state.riskProfileChart.data?.data} layout={state.riskProfileChart.data?.layout} />
        </CStatefulContainer>
        <CStatefulContainer
          submodule
          apiRequestState={state.riskProfileTable.state}
          showContent={!!state.riskProfileTable.data && state.riskProfileTable.minDate > 0}
        >
          <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CPageModule submodule>
              <div className={'flex justify-end'}>
                <GenericDateRangeSelector
                  initStartDate={state.riskProfileTable.minDate}
                  initEndDate={state.riskProfileTable.maxDate}
                  minDateSpan={10}
                  minDate={state.riskProfileTable.minDate}
                  maxDate={state.riskProfileTable.maxDate}
                  onChange={async (startUnix, endUnix) => {
                    await updateStateResponseStatefulAttribute<State, typeof state.riskProfileTable.data>(
                      { riskProfileTable: state.riskProfileTable },
                      setState,
                      async () =>
                        await PublicFactsheetServices.getPerformanceAttributionRiskProfileTable(factsheetId ?? '', {
                          locale: locale ?? 'de-DE',
                          startDate: dayjs(startUnix).format('YYYY-MM-DD'),
                          endDate: dayjs(endUnix).format('YYYY-MM-DD')
                        })
                    );
                  }}
                />
              </div>
            </CPageModule>
            <CHorizontalScrollContainer>
              <CPandasTable data={state.riskProfileTable.data?.table} colSpanArr={[1, 1, 1]} />
            </CHorizontalScrollContainer>
          </CPageModule>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth style={{ paddingTop: 0 }}>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fspaP6H1'} />
          </h3>
          <p>
            <CLocalizedText dictKey={'fspaP6Des'} />
          </p>
          <ul>
            <li>
              <CLocalizedText dictKey={'fspaP6item1'} />
            </li>
            <li>
              <CLocalizedText dictKey={'fspaP6item2'} />
            </li>
            <li>
              <CLocalizedText dictKey={'fspaP6item3'} />
            </li>
          </ul>
          <p>
            <CLocalizedText dictKey={'fspaP6item4'} />
          </p>
        </CPageModule>
        <CTab submodule>
          <CTabItem label={'economic'} text={<CLocalizedText dictKey={'fspaP6tab1'} />}>
            <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
              <CStatefulContainer apiRequestState={state.breakdowns.state} showContent={!!state.breakdowns.data}>
                <CPageModulePlot
                  data={state.breakdowns.data?.economic.data}
                  layout={state.breakdowns.data?.economic.layout}
                />
              </CStatefulContainer>
            </CPageModule>
          </CTabItem>
          <CTabItem label={'instrumental'} text={<CLocalizedText dictKey={'fspaP6tab2'} />}>
            <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
              <CStatefulContainer apiRequestState={state.breakdowns.state} showContent={!!state.breakdowns.data}>
                <CPageModulePlot
                  data={state.breakdowns.data?.instrumental.data}
                  layout={state.breakdowns.data?.instrumental.layout}
                />
              </CStatefulContainer>
            </CPageModule>
          </CTabItem>
        </CTab>
      </CPageModule>
      {/* <CPageModule fullWidth> */}
      {/*   <CPageModule submodule> */}
      {/*     <h2>{<CLocalizedText dictKey={'fspaP2H1'} />}</h2> */}
      {/*     <p>{<CLocalizedText dictKey={'fspaP2Desc'} />}</p> */}
      {/*   </CPageModule> */}
      {/*   <CStatefulContainer submodule apiRequestState={state.overview.state} showContent={!!state.overview.data}> */}
      {/*     <CPageModulePlot data={state.overview.data?.data} layout={state.overview.data?.layout} /> */}
      {/*   </CStatefulContainer> */}
      {/* </CPageModule> */}
      {/* <CPageModule fullWidth> */}
      {/*   <CPageModule submodule> */}
      {/*     <h2>{<CLocalizedText dictKey={'fspaP3H1'} />}</h2> */}
      {/*     <p>{<CLocalizedText dictKey={'fspaP3Desc'} />}</p> */}
      {/*   </CPageModule> */}
      {/*   <CStatefulContainer */}
      {/*     submodule */}
      {/*     apiRequestState={state.performanceComponents.state} */}
      {/*     showContent={!!state.performanceComponents.data} */}
      {/*   > */}
      {/*     <CPageModulePlot */}
      {/*       data={state.performanceComponents.data?.data} */}
      {/*       layout={state.performanceComponents.data?.layout} */}
      {/*     /> */}
      {/*   </CStatefulContainer> */}
      {/* </CPageModule> */}
      <CPageModule>{factsheetNavigator}</CPageModule>
      <CFactsheetDisclaimer />
    </Page>
  );
}

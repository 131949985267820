import { FilterList } from '@mui/icons-material';
import React, { type CSSProperties, useEffect } from 'react';

import { updateState, useStateExtended } from '../helpers/helper';
import { type PaginatedBackendResponse } from '../models/GhostPostModel';
import { type IFilterMeta } from '../models/IFilterMeta';
import { type IRequestState } from '../models/IRequestState';
import { type ISearchMeta } from '../models/ISearchMeta';
import { type ISortingMeta } from '../models/ISortingMeta';
import { type TPandasTable } from '../models/TPandasTable';
import CDropdownItemHeader from './CDropdownItemHeader';
import CHorizontalScrollContainer from './CHorizontalScrollContainer';
import CLocalizedText from './CLocalizedText';
import CPageModule from './CPageModule';
import CPandasTable, { type CPandasTableProps } from './CPandasTable';
import CSearchBar from './CSearchBar';
import XDButton from './buttons/XDButton';
import XDCheckbox from './forms/XDCheckbox';
import XDPaginator from './layout/XDPaginator';
import XDDropdown from './selection/XDDropdown';

interface Props
  extends Pick<
    CPandasTableProps,
    'colSpanArr' | 'colAlignmentArr' | 'colFormatArr' | 'style' | 'onRowClick' | 'rowLinks' | 'showIndex'
  > {
  state: IRequestState | undefined; // Deprecate
  data: PaginatedBackendResponse<TPandasTable> | undefined;
  params?: Partial<{ sorting: ISortingMeta } & ISearchMeta & IFilterMeta>;
  onChange?: (page: number, sorting: ISortingMeta, search: string, filters: string[]) => void;
  searchBarName?: string;
  cellHeight?: CSSProperties['height'];
  searchBarPlaceholder?: string;
}

interface State {
  filterArray: string[];
}

export default function CPageModulePaginatedTable(props: Props) {
  const [state, setState, getState] = useStateExtended<State>({
    filterArray: []
  });
  const handleSelectFilter = (key: string, ix: number) => {
    const identifier = key.slice(0, 2) + ix;
    const identifierIndex = state.filterArray.indexOf(identifier);
    const filterArray = [...state.filterArray];
    if (identifierIndex > -1) {
      filterArray.splice(identifierIndex, 1);
    } else {
      filterArray.push(identifier);
    }
    updateState<State>({ filterArray }, state, setState);
  };

  useEffect(() => {
    if (!props.data?.meta?.filter?.applied) return;
    updateState<State>(
      { filterArray: props.data?.meta.filter.applied.split(',').filter((e) => e !== '') },
      state,
      setState
    );
  }, [props.data?.meta?.filter]);

  return (
    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
      <>
        {props.searchBarName && (
          <CPageModule submodule style={{ paddingTop: 0 }}>
            <div className={'flex gap-4 items-center'}>
              <CSearchBar
                key={props.searchBarName}
                initSearchText={props.params?.search}
                label={<CLocalizedText dictKey={'globalSearchCompany'} />}
                placeholder={props.searchBarPlaceholder ?? ''}
                onSubmit={(searchText) => {
                  if (props.onChange)
                    props.onChange(
                      1,
                      {
                        sortCol: props.params?.sorting?.sortCol ?? 0,
                        ascending: props.params?.sorting?.ascending ?? 'ascending'
                      },
                      searchText,
                      state.filterArray
                    );
                }}
              />
              {props.data?.meta?.filter && (
                <XDDropdown
                  button={
                    <XDButton className={'font-bold'} style={{ minHeight: '3.5rem' }}>
                      <CLocalizedText dictKey={'globalFilter'} />
                      {state.filterArray.length > 0 && <>({state.filterArray.length}) </>} <FilterList />
                    </XDButton>
                  }
                  onClose={async () => {
                    if (props.onChange && (await getState()).filterArray.join(',') !== props.data?.meta.filter.applied)
                      props.onChange(
                        1,
                        {
                          sortCol: props.params?.sorting?.sortCol ?? 0,
                          ascending: props.params?.sorting?.ascending ?? 'ascending'
                        },
                        props.params?.search ?? '',
                        (await getState()).filterArray
                      );
                  }}
                >
                  {Object.keys(props.data.meta.filter.options).map((e) => (
                    <div key={e} className={'flex flex-col'} style={{ hyphens: 'none' }}>
                      <CDropdownItemHeader
                        rightDecoration={
                          <a
                            className={'flex-grow text-right text-xs'}
                            style={{ opacity: state.filterArray.length > 0 ? 1 : 0 }}
                            onClick={() => {
                              updateState<State>({ filterArray: [] }, state, setState);
                            }}
                          >
                            <CLocalizedText dictKey={'globalClearAll'} />
                          </a>
                        }
                      >
                        {e.slice(2)}
                      </CDropdownItemHeader>
                      {props.data?.meta.filter.options[e].map((f, ix) => (
                        <XDCheckbox
                          key={f}
                          onChange={() => {
                            handleSelectFilter(e, ix);
                          }}
                          checked={state.filterArray.includes(e.slice(0, 2) + ix)}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {f}
                        </XDCheckbox>
                      ))}
                    </div>
                  ))}
                </XDDropdown>
              )}
            </div>
          </CPageModule>
        )}
      </>
      <CHorizontalScrollContainer>
        <CPandasTable
          data={props.data?.results}
          colSpanArr={props.colSpanArr}
          onSortChange={(sortCol, ascending) => {
            if (props.onChange)
              props.onChange(1, { sortCol, ascending }, props.params?.search ?? '', state.filterArray);
          }}
          sorting={props.params?.sorting}
          colFormatArr={props.colFormatArr}
          colAlignmentArr={props.colAlignmentArr}
          style={{ ...props.style }}
          cellHeight={props.cellHeight}
          onRowClick={props.onRowClick}
          rowLinks={props.rowLinks}
          showIndex={props.showIndex}
        />
      </CHorizontalScrollContainer>
      <CPageModule submodule style={{ paddingTop: 0 }}>
        <div className={'flex justify-center'}>
          <XDPaginator
            apiReqState={{ isError: false, isLoading: false }}
            pagination={props.data?.meta.pagination}
            retrievePageCallback={(page) => {
              if (props.onChange)
                props.onChange(
                  page,
                  {
                    sortCol: props.params?.sorting?.sortCol ?? 0,
                    ascending: props.params?.sorting?.ascending ?? 'ascending'
                  },
                  props.params?.search ?? '',
                  state.filterArray
                );
            }}
            dontScrollOnReload
          />
        </div>
      </CPageModule>
    </CPageModule>
  );
}

import { Close, Menu } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { disableScroll } from '../../helpers/helper';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { updateDashboardDim, updateHeaderDim } from '../../store/appSlice';
import ImageWrapper from '../ImageWrapper';
import MobileMenu from './MobileMenu';

interface State {
  isMenuExpanded?: boolean;
}

interface headerProps {
  mobileNavItems?: JSX.Element;
  headerFloatRightItems?: JSX.Element;
}

export default function Header({ mobileNavItems, headerFloatRightItems }: headerProps) {
  const location = useLocation();
  const effectiveWidthRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [state, setState] = useState<State>({});
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { isMenuExpanded } = state;
  const headerDom = useRef<HTMLDivElement>(null);
  const { breadcrumbReplacementTextDict } = useAppSelector((state) => state.dashboard);

  const doLayoutCalculations = () => {
    updateState({ isMenuExpanded: false });
    dispatch(
      updateHeaderDim({
        h: headerDom.current?.getBoundingClientRect().height ?? 0,
        w: effectiveWidthRef.current?.getBoundingClientRect().width ?? 0
      })
    );
    dispatch(updateDashboardDim({ h: window.innerHeight - (headerDom.current?.getBoundingClientRect().height ?? 0) }));
  };

  useEffect(() => {
    const slug = location.pathname.split('/').pop();
    const index = Object.keys(breadcrumbReplacementTextDict).findIndex((e) => e === slug);
    const label = Object.values(breadcrumbReplacementTextDict)[index];
    if (!label) return;
    document.title = `${label} - neXDos`;
  }, [location.pathname, breadcrumbReplacementTextDict]);

  useEffect(() => {
    disableScroll(isMenuExpanded ?? false);
  }, [isMenuExpanded]);

  useEffect(() => {
    doLayoutCalculations();
    window.addEventListener('resize', doLayoutCalculations);
    return () => {
      window.removeEventListener('resize', doLayoutCalculations);
    };
  }, []);

  return (
    <>
      <div className={'w-full'} ref={effectiveWidthRef} />
      <div
        className={
          'fixed top-0 z-50 flex gap-0 md:gap-4 border-b bg-white items-center before:bg-white before:w-full before:h-14 before:absolute before:-z-10'
        }
        id={'header'}
        ref={headerDom}
        style={{ width: effectiveWidthRef.current?.getBoundingClientRect().width ?? 0 }}
      >
        {(headerFloatRightItems != null || mobileNavItems != null) && (
          <div className={'button md:hidden w-20 pl-1'}>
            <div
              className={'aspect-square flex items-center justify-center h-12 w-12 cursor-pointer'}
              style={{ fontSize: '1.5rem', padding: '0.8rem' }}
              onClick={() => {
                updateState({ isMenuExpanded: !isMenuExpanded });
              }}
            >
              <span className={'pointer-events-none -mt-1'}>{!isMenuExpanded ? <Menu /> : <Close />}</span>
              <MobileMenu
                isMenuExpanded={isMenuExpanded ?? false}
                onClose={() => {
                  updateState({ isMenuExpanded: false });
                }}
                mobileNavItems={mobileNavItems}
              />
            </div>
          </div>
        )}
        <div className={'flex-grow flex py-3 justify-center md:justify-start px-4'}>
          <Link to={'/'}>
            <ImageWrapper alt={'neXDos GmbH'} src={'/images/logo.png'} className={'h-6 my-1'} />
          </Link>
        </div>
        <div className={'w-20 flex justify-end pr-4'}>
          <span className={'flex gap-4'}>{headerFloatRightItems}</span>
        </div>
      </div>
    </>
  );
}

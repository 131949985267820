export enum PageRoutes {
  HOME = 'home',
  NEWS_OUTLET = 'news',
  NEWS_HOME = '',
  NEWS_ARTICLES_OUTLET = 'articles',
  NEWS_ARTICLES_HOME = '',
  LOGIN = 'login',
  DASHBOARD_HOME = '',
  DASHBOARD_OUTLET = '',
  PUBLISHING_OUTLET = 'publishing',
  PUBLISHING_HOME = '',
  PUBLISHING_FS_OUTLET = 'fs-links',
  PUBLISHING_FS_INDEX = '',
  PUBLISHING_FS_NEW_LINK = 'new',
  PUBLISHING_FS_OVERVIEW_OUTLET = 'links',
  PUBLISHING_FS_OVERVIEW_INDEX = '',
  PAGE_NOT_FOUND = '*',
  USERS_OUTLET = 'users',
  USERS_HOME = '',
  USERS_NEW = 'new',
  USERS_BACKTEST = 'backtest',
  USERS_EDIT = 'edit',
  USERS_DELETE = 'delete',
  USERS_OVERVIEW_INDEX = '',
  USERS_OVERVIEW_OUTLET = 'accounts',
  USERS_USER_HUB_OUTLET = 'dossier',
  USERS_USER_HUB_INDEX = '',
  BACKTEST_OUTLET = 'backtest',
  BACKTEST_HOME = '',
  FACTSHEET_OUTLET = 'factsheet',
  FACTSHEET_HOME = '',
  PUBLIC_FACTSHEET_COLLECTION_OUTLET = 'fs',
  PUBLIC_FACTSHEET_INDEX = '',
  PUBLIC_FACTSHEET_FACTOR_EXPOSURE = 'factorExposure',
  PUBLIC_FACTSHEET_SECTOR_EXPOSURE = 'sectorExposure',
  PUBLIC_FACTSHEET_PERFORMANCE_ATTRIBUTION = 'performanceAttribution',
  PUBLIC_FACTSHEET_ESG = 'esg',
  PUBLIC_FACTSHEET_DELTA = 'delta',
  PUBLIC_FACTSHEET_PERFORMANCE = 'performance',
  // Single Stock
  SINGLE_STOCK_OUTLET = 'singleStock',
  SINGLE_STOCK_INDEX = '',
  // News Ops
  OPS_NEWS_OUTLET = 'ops',
  OPS_NEWS_INDEX = ''
}

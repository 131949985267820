import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { FilterList, OpenInNew } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import CLocalizedText from '../../../components/CLocalizedText';
import CModal from '../../../components/CModal';
import { type IPageModule } from '../../../components/CPageModule';
import TagButton from '../../../components/TagButton';
import XDEmptyResult from '../../../components/alerts/XDEmptyResult';
import XDButton from '../../../components/buttons/XDButton';
import ActionButton from '../../../components/dashboardContent/ActionButton';
import XDCheckbox from '../../../components/forms/XDCheckbox';
import XDResponsiveGridWrapper from '../../../components/layout/XDResponsiveGridWrapper';
import XDDropdown from '../../../components/selection/XDDropdown';
import { BreakpointsInPx } from '../../../enums/BreakpointsInPx';
import { ETonalButtonClassNames } from '../../../enums/ETagButtonColors';
import HDate from '../../../helpers/HDate';
import { updateState, updateStateAttribute } from '../../../helpers/helper';
import { useAppSelector } from '../../../hooks/hooks';
import { type IResponseStateful } from '../../../models/IResponseStateful';
import { type MSingleStockSummarizedInfo } from '../../../models/singleStock/details/MSingleStockSummarizedInfo';
import { type SingleStockCurrentHistoricalInformation } from '../../../models/singleStock/details/SingleStockCurrentHistoricalInformation';
import { type SingleStockKeyEvent } from '../../../models/singleStock/details/SingleStockKeyEvent';
import { type SingleStockLastDividendPayment } from '../../../models/singleStock/details/SingleStockLastDividendPayment';
import { type SingleStockLastStockSplit } from '../../../models/singleStock/details/SingleStockLastStockSplit';

type EventItems = Partial<
  | SingleStockCurrentHistoricalInformation
  | SingleStockLastStockSplit
  | SingleStockLastDividendPayment
  | SingleStockKeyEvent
>;

interface KeyEventsModalState {
  modalIsVisible: boolean;
  eventArray?: EventItems[];
}

export default function CKeyEvents(props: { info?: IResponseStateful<MSingleStockSummarizedInfo> }) {
  const { dashboardOutletDimensions } = useAppSelector((state) => state.dashboard);
  const [state, setState] = useState<KeyEventsModalState>({ modalIsVisible: false });

  const recomputeEventArray = () => {
    const eventArray: KeyEventsModalState['eventArray'] = [];

    if (props.info?.data?.currenthistoricalinformation) {
      eventArray.push(...props.info.data.currenthistoricalinformation);
    }

    if (props?.info?.data?.keyevents) {
      eventArray.push(...props.info.data.keyevents);
    }

    if (props?.info?.data?.lastdividendpayments) {
      eventArray.push(...props.info.data.lastdividendpayments);
    }

    if (props.info?.data?.laststocksplits) {
      eventArray.push(...props.info.data.laststocksplits);
    }

    updateStateAttribute<KeyEventsModalState>(
      {
        eventArray: eventArray.sort((a, b) => {
          return (a.commontimestamp ?? 0) > (b.commontimestamp ?? 0) ? -1 : 1;
        })
      },
      setState
    );
  };

  useEffect(() => {
    recomputeEventArray();
  }, [props.info]);

  return (
    <>
      <XDResponsiveGridWrapper type={'gallery'} className={'bg-gray-50 p-4 rounded-xl border'} widerBox>
        {state.eventArray
          ?.slice(0, dashboardOutletDimensions.w > BreakpointsInPx.BREAKPOINT_3 ? 2 : 1)
          .map((e, ix) => CardFactory(e, ix, false))}
        {(state.eventArray?.length ?? 0) > 0 && (
          <ActionButton
            label={
              <>
                <CLocalizedText dictKey={'globalViewAll'} inline />
                <span> ({state.eventArray?.length})</span>
              </>
            }
            onClick={() => {
              updateStateAttribute<KeyEventsModalState>({ modalIsVisible: true }, setState);
            }}
            style={{
              aspectRatio: dashboardOutletDimensions.w > 750 ? 'auto' : 5.5,
              height: '100%'
            }}
          >
            <ArrowRightIcon />
          </ActionButton>
        )}
      </XDResponsiveGridWrapper>
      <CModal
        onClose={() => {
          updateStateAttribute<KeyEventsModalState>({ modalIsVisible: false }, setState);
        }}
        open={state.modalIsVisible}
      >
        {/* <CPageModule style={{ paddingTop: 0 }}> */}
        <KeyEventsModalItem eventArray={state.eventArray} />
        {/* </CPageModule> */}
        {/* <CPageModule style={{ paddingTop: 0 }} /> */}
      </CModal>
    </>
  );
}

function CardFactory(eventType: EventItems, ix: number, outline: boolean) {
  switch (eventType.eventtype) {
    case 'historical':
      return (
        <HistoricalEventCard
          outline={outline}
          key={(eventType as SingleStockCurrentHistoricalInformation).uuid}
          order={ix}
          event={eventType as SingleStockCurrentHistoricalInformation}
        />
      );
    case 'keyEvents':
      return (
        <KeyEventCard
          outline={outline}
          key={(eventType as SingleStockKeyEvent).uuid}
          order={ix}
          event={eventType as SingleStockKeyEvent}
        />
      );
    case 'dividendPayments':
      return (
        <DividendPaymentEventCard
          outline={outline}
          key={(eventType as SingleStockLastDividendPayment).uuid}
          order={ix}
          event={eventType as SingleStockLastDividendPayment}
        />
      );
    case 'stockSplits':
      return (
        <StockSplitEventCard
          outline={outline}
          key={(eventType as SingleStockLastStockSplit).uuid}
          order={ix}
          event={eventType as SingleStockLastStockSplit}
        />
      );
    default:
      return <></>;
  }
}

interface State {
  includeHistorical: boolean;
  includeKeyEvents: boolean;
  includeDividendPayments: boolean;
  includeStockSplits: boolean;
}

type EventTypes = 'historical' | 'keyEvents' | 'dividendPayments' | 'stockSplits';

interface Props extends Pick<IPageModule, 'divRef'> {
  eventArray?: EventItems[];
}

function KeyEventsModalItem(props: Props) {
  const [state, setState] = useState<State>({
    includeHistorical: true,
    includeDividendPayments: true,
    includeKeyEvents: true,
    includeStockSplits: true
  });

  const handleCheckboxChange = (eventType: EventTypes) => {
    switch (eventType) {
      case 'historical':
        updateState<State>({ includeHistorical: !state.includeHistorical }, state, setState);
        break;
      case 'keyEvents':
        updateState<State>({ includeKeyEvents: !state.includeKeyEvents }, state, setState);
        break;
      case 'stockSplits':
        updateState<State>({ includeStockSplits: !state.includeStockSplits }, state, setState);
        break;
      case 'dividendPayments':
        updateState<State>({ includeDividendPayments: !state.includeDividendPayments }, state, setState);
        break;
    }
  };

  const filterButton = (
    <div className={'-mr-2'}>
      <XDDropdown
        topShift={'3rem'}
        button={
          <XDButton className={'text-sm font-bold'} propagateClick>
            <CLocalizedText dictKey={'globalFilter'} inline /> (
            {
              [
                state.includeHistorical,
                state.includeKeyEvents,
                state.includeStockSplits,
                state.includeDividendPayments
              ].filter((e) => e).length
            }
            )
            <FilterList />
          </XDButton>
        }
      >
        <div className={'flex flex-col gap-1'}>
          {(props.eventArray?.filter((e) => e.eventtype === 'historical').length ?? 0) > 0 && (
            <XDCheckbox
              style={{ minHeight: 0, padding: '0.25rem' }}
              checked={state.includeHistorical}
              onChange={() => {
                handleCheckboxChange('historical');
              }}
            >
              <TagButton color={ETonalButtonClassNames.TEAL_PPT_2}>
                <CLocalizedText dictKey={'singleStockEventHistoricalEvent'} />
              </TagButton>
            </XDCheckbox>
          )}
          {(props.eventArray?.filter((e) => e.eventtype === 'keyEvents').length ?? 0) > 0 && (
            <XDCheckbox
              style={{ minHeight: 0, padding: '0.25rem' }}
              checked={state.includeKeyEvents}
              onChange={() => {
                handleCheckboxChange('keyEvents');
              }}
            >
              <TagButton color={ETonalButtonClassNames.TEAL_PPT_2}>
                <CLocalizedText dictKey={'singleStockEventKeyEvent'} />
              </TagButton>
            </XDCheckbox>
          )}
          {(props.eventArray?.filter((e) => e.eventtype === 'dividendPayments').length ?? 0) > 0 && (
            <XDCheckbox
              style={{ minHeight: 0, padding: '0.25rem' }}
              checked={state.includeDividendPayments}
              onChange={() => {
                handleCheckboxChange('dividendPayments');
              }}
            >
              <TagButton color={ETonalButtonClassNames.TEAL_PPT_6}>
                <CLocalizedText dictKey={'singleStockEventDividendPayment'} />
              </TagButton>
            </XDCheckbox>
          )}
          {(props.eventArray?.filter((e) => e.eventtype === 'stockSplits').length ?? 0) > 0 && (
            <XDCheckbox
              style={{ minHeight: 0, padding: '0.25rem' }}
              checked={state.includeStockSplits}
              onChange={() => {
                handleCheckboxChange('stockSplits');
              }}
            >
              <TagButton className={'bg-teal-ppt-9 bg-opacity-10 text-teal-ppt-9 whitespace-nowrap'}>
                <CLocalizedText dictKey={'singleStockEventStockSplit'} />
              </TagButton>
            </XDCheckbox>
          )}
        </div>
      </XDDropdown>
    </div>
  );
  const filteredArray = props.eventArray?.filter((e) => {
    return (
      (state.includeDividendPayments && e.eventtype === 'dividendPayments') ||
      (state.includeHistorical && e.eventtype === 'historical') ||
      (state.includeKeyEvents && e.eventtype === 'keyEvents') ||
      (state.includeStockSplits && e.eventtype === 'stockSplits')
    );
  });

  return (
    <div className={'flex flex-col gap-1'}>
      <div className={'flex gap-4 justify-end flex-wrap items-center'}>
        {
          <>
            <p className={'flex-grow'}>
              {filteredArray?.length} <CLocalizedText dictKey={'entriesFound'} inline />
            </p>
            {filterButton}
          </>
        }
      </div>
      <div className={'rounded-lg overflow-hidden border'}>
        <div className={'p-4 bg-gray-50 overflow-y-auto '}>
          <XDResponsiveGridWrapper type={'list'}>
            <>
              {(filteredArray?.length ?? 0) === 0 && <XDEmptyResult />}
              {filteredArray?.map((e, ix) => CardFactory(e, ix, false))}
            </>
          </XDResponsiveGridWrapper>
        </div>
      </div>
    </div>
  );
}

function EventCard(props: {
  children?: JSX.Element;
  eventType: EventTypes;
  commonTimestamp: number;
  className?: string;
  order: number;
  outline: boolean;
}) {
  const classNames = ['p-4 rounded-xl flex flex-col gap-1 bg-white'];

  if (props.className) {
    classNames.push(props.className);
  }

  if (props.outline) {
    classNames.push('border');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className={'uppercase font-bold text-sm flex gap-2 grow items-start mb-1'}>
        <p className={'opacity-80'}>{HDate.getShortDate(new Date(props.commonTimestamp * 1000))}</p>
        {props.eventType === 'historical' && (
          <TagButton marginTopInRem={-0.1} color={ETonalButtonClassNames.TEAL_PPT_2}>
            <CLocalizedText dictKey={'singleStockEventHistoricalEvent'} />
          </TagButton>
        )}
        {props.eventType === 'keyEvents' && (
          <TagButton marginTopInRem={-0.1} color={ETonalButtonClassNames.TEAL_PPT_4}>
            <CLocalizedText dictKey={'singleStockEventKeyEvent'} />
          </TagButton>
        )}
        {props.eventType === 'dividendPayments' && (
          <TagButton marginTopInRem={-0.1} color={ETonalButtonClassNames.TEAL_PPT_6}>
            <CLocalizedText dictKey={'singleStockEventDividendPayment'} />
          </TagButton>
        )}
        {props.eventType === 'stockSplits' && (
          <TagButton marginTopInRem={-0.1} color={ETonalButtonClassNames.TEAL_PPT_9}>
            <CLocalizedText dictKey={'singleStockEventStockSplit'} />
          </TagButton>
        )}
      </div>
      {props.children}
    </div>
  );
}

function HistoricalEventCard(props: {
  event: SingleStockCurrentHistoricalInformation;
  order: number;
  outline: boolean;
}) {
  return (
    <EventCard
      eventType={'historical'}
      commonTimestamp={props.event.commontimestamp}
      order={props.order}
      outline={props.outline}
    >
      <>
        <div className={'flex flex-col gap-0'}>
          {!props.event.gsector.includes('Not disclosed') && (
            <p>
              <b>Sector:</b> {props.event.gsector}
            </p>
          )}
          {!props.event.ggroup.includes('Not disclosed') && (
            <p>
              <b>Group:</b> {props.event.ggroup}
            </p>
          )}
          {!props.event.gind.includes('Not disclosed') && (
            <p>
              <b>Industry:</b> {props.event.gind}
            </p>
          )}
          {!props.event.gsubind.includes('Not disclosed') && (
            <p>
              <b>Sub-industry:</b> {props.event.gsubind}
            </p>
          )}
        </div>
      </>
    </EventCard>
  );
}

function KeyEventCard(props: { event: SingleStockKeyEvent; order: number; outline: boolean }) {
  const desc: JSX.Element[] = [];

  if (props.event.document_name) {
    desc.push(<span>{props.event.document_name}</span>);
  }

  if (props.event.form_type) {
    desc.push(<span>Form {props.event.form_type}</span>);
  }

  if (props.event.description) {
    if (props.event.link) {
      desc.push(
        <a href={props.event.link} target={'_blank'} rel="noreferrer">
          {props.event.description} <OpenInNew />
        </a>
      );
    } else {
      desc.push(<span>{props.event.description}</span>);
    }
  }

  return (
    <EventCard
      eventType={'keyEvents'}
      commonTimestamp={props.event.commontimestamp}
      order={props.order}
      outline={props.outline}
    >
      <>
        <h4>{props.event.event_group}</h4>
        <div className={'inline'}>
          {desc.map((e, ix) => (
            <span key={ix}>
              {e} {ix !== desc.length - 1 ? '• ' : undefined}
            </span>
          ))}
        </div>
      </>
    </EventCard>
  );
}

function DividendPaymentEventCard(props: { event: SingleStockLastDividendPayment; order: number; outline: boolean }) {
  const desc: string[] = [];

  if (props.event.divamount) {
    if (props.event.currencyname) {
      desc.push(`${props.event.divamount} ${props.event.currencyname}`);
    } else {
      desc.push(props.event.divamount.toString());
    }
  }

  if (props.event.recorddate) {
    desc.push(`Recorded on ${HDate.getShortDate(new Date(props.event.recorddate))}`);
  }

  if (props.event.paydate) {
    desc.push(`Paid out on ${HDate.getShortDate(new Date(props.event.paydate))}`);
  }

  return (
    <EventCard
      eventType={'dividendPayments'}
      commonTimestamp={props.event.commontimestamp}
      order={props.order}
      outline={props.outline}
    >
      <>
        <h4>{props.event.supplementaltypename}</h4>
        <p>{desc.join(' • ')}</p>
      </>
    </EventCard>
  );
}

function StockSplitEventCard(props: { event: SingleStockLastStockSplit; order: number; outline: boolean }) {
  return (
    <EventCard
      eventType={'stockSplits'}
      commonTimestamp={props.event.commontimestamp}
      order={props.order}
      outline={props.outline}
    >
      <>
        <h4>Stock split by a factor of {props.event.split_factor}</h4>
        {typeof props.event.announced_date === 'string' && (
          <p>Announced on {HDate.getShortDate(new Date(props.event.announced_date))}</p>
        )}
      </>
    </EventCard>
  );
}

import { DeleteForever } from '@mui/icons-material';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CustomTextField from '../../../components/CustomTextField';
import PageModuleFormGroup from '../../../components/PageModuleFormGroup';
import PageModuleFormRow from '../../../components/PageModuleFormRow';
import ButtonXd from '../../../components/dashboardContent/ButtonXd';
import XDSwitch from '../../../components/forms/XDSwitch';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import PageModuleTitle from '../../../components/layout/PageModuleTitle';
import XDTextFieldDisplay from '../../../components/presentation/XDTextFieldDisplay';
import { PageRoutes } from '../../../enums/enums';
import { updateState } from '../../../helpers/helper';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { type IRequestState } from '../../../models/IRequestState';
import { type FactsheetAdminDetail } from '../../../models/publicFactsheetModel';
import PublicFactsheetServices from '../../../services/publicFactsheetServices';
import { updateSnackbar } from '../../../store/appSlice';

interface State {
  pageTitle?: string;
  reqState?: IRequestState;
  putState?: IRequestState;
  adminFactsheetDetail?: FactsheetAdminDetail;
  form?: Partial<FactsheetAdminDetail>;
  deleteState?: IRequestState;
}

export default function FactsheetManagementEntry() {
  const { locale } = useAppSelector((state) => state.dashboard);
  const navigate = useNavigate();
  const pathName = useLocation().pathname.split('/');
  const dispatch = useAppDispatch();
  const linkId = pathName[pathName.length - 1];

  const [state, setState] = useState<State>({});

  const computeFormattedName = (name: string, id: string) => {
    return name === id ? id : `${name} (${id})`;
  };

  const handleDelete = async () => {
    updateState<State>({ deleteState: { isError: false, isLoading: true } }, state, setState);
    const res = await PublicFactsheetServices.deleteFSLink(linkId);
    updateState<State>(
      { deleteState: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() } },
      state,
      setState
    );
    if (!res.hasError()) {
      navigate(
        `/${PageRoutes.PUBLISHING_OUTLET}/${PageRoutes.PUBLISHING_FS_OUTLET}/${PageRoutes.PUBLISHING_FS_OVERVIEW_OUTLET}`
      );
    }
  };

  const handleFormChange = (partialForm: Partial<FactsheetAdminDetail>) => {
    updateState<State>({ form: { ...state.adminFactsheetDetail, ...partialForm } }, state, setState);
  };

  const handleSaveChanges = async () => {
    updateState<State>({ putState: { isError: false, isLoading: true } }, state, setState);
    const res = await PublicFactsheetServices.putFSLink(linkId, state.form ?? {});
    updateState<State>(
      { putState: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() } },
      state,
      setState
    );
    if (!res.hasError()) {
      updateState<State>(
        {
          adminFactsheetDetail: res.getData(),
          form: res.getData(),
          pageTitle: computeFormattedName(res.getData().name, res.getData().id)
        },
        state,
        setState
      );
      dispatch(updateSnackbar({ message: 'Changes were successefully saved', severity: 'success' }));
    }
  };

  const handleSwitchChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange({ enabled: ev.target.checked });
    updateState<State>({ putState: { isError: false, isLoading: true } }, state, setState);
    const res = await PublicFactsheetServices.putFSLink(linkId, { enabled: ev.target.checked });
    updateState<State>(
      { putState: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() } },
      state,
      setState
    );
    if (!res.hasError()) {
      updateState<State>({ adminFactsheetDetail: res.getData() }, state, setState);
      dispatch(
        updateSnackbar({
          message: `Link was successfully ${ev.target.checked ? 'enabled' : 'disabled'}`,
          severity: 'success'
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      updateState<State>({ reqState: { isError: false, isLoading: true } }, state, setState);
      const res = await PublicFactsheetServices.getFactsheetAdminDetail(linkId);
      updateState<State>(
        { reqState: { isError: res.hasError(), isLoading: false, strError: res.getErrorString() } },
        state,
        setState
      );
      if (!res.hasError()) {
        const name = res.getData().name;
        const id = res.getData().id;
        updateState<State>(
          { adminFactsheetDetail: res.getData(), form: res.getData(), pageTitle: computeFormattedName(name, id) },
          state,
          setState
        );
      }
    })();
  }, []);

  return (
    <Page title={state.pageTitle}>
      <PageModule apiRequestState={state.reqState} showContent={!!state.adminFactsheetDetail}>
        <PageModuleTitle subtitle={'Factsheet Link'}>{state.adminFactsheetDetail?.name}</PageModuleTitle>
        <PageModuleFormGroup>
          <PageModuleFormRow>
            <XDTextFieldDisplay label={'Link ID'}>{state.adminFactsheetDetail?.id}</XDTextFieldDisplay>
          </PageModuleFormRow>
          <PageModuleFormRow>
            <XDTextFieldDisplay label={'Unique factsheet link'}>
              <Link to={`/${PageRoutes.PUBLIC_FACTSHEET_COLLECTION_OUTLET}/${linkId}`} className={'font-normal'}>
                {window.location.href.split('/').slice(0, 3).join('/')}/{PageRoutes.PUBLIC_FACTSHEET_COLLECTION_OUTLET}/
                {linkId}
              </Link>
            </XDTextFieldDisplay>
          </PageModuleFormRow>
          <PageModuleFormRow>
            <XDTextFieldDisplay label={'Created by'}>{state.adminFactsheetDetail?.created_by}</XDTextFieldDisplay>
          </PageModuleFormRow>
          <PageModuleFormRow>
            <XDTextFieldDisplay label={'Created on'}>
              {new Date(state.adminFactsheetDetail?.created_timestamp ?? 0).toLocaleString(locale, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short'
              })}
            </XDTextFieldDisplay>
          </PageModuleFormRow>
          <PageModuleFormRow>
            <XDTextFieldDisplay label={'All-time view count'}>
              {state.adminFactsheetDetail?.global_view_count ?? 0}
            </XDTextFieldDisplay>
          </PageModuleFormRow>
          {state.adminFactsheetDetail?.last_access && (
            <PageModuleFormRow description={''}>
              <XDTextFieldDisplay label={'Last view'}>
                {new Date(state.adminFactsheetDetail.last_access[0]?.ts ?? '').toLocaleString(locale, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  timeZoneName: 'short'
                })}
              </XDTextFieldDisplay>
            </PageModuleFormRow>
          )}
        </PageModuleFormGroup>
      </PageModule>
      {state.adminFactsheetDetail && (
        <>
          <PageModule background={<div className={'bg-gray-50'} />}>
            <PageModuleTitle level={'h3'}>Edit link</PageModuleTitle>
            <PageModuleFormGroup>
              <PageModuleFormRow description={'When disabled, the link will return an error message.'}>
                <XDSwitch checked={state.form?.enabled} onChange={handleSwitchChange}>
                  Enable link
                </XDSwitch>
              </PageModuleFormRow>
              <PageModuleFormRow
                description={
                  'Description of the link. Only visible to administrators. If blank, this value will be set to the link ID by default.'
                }
              >
                <CustomTextField
                  label={'Link name (optional)'}
                  className={'sm:w-full'}
                  value={state.form?.name}
                  onChange={(e) => {
                    handleFormChange({ name: e.target.value });
                  }}
                />
              </PageModuleFormRow>
              <PageModuleFormRow description={'Email address of the user.'}>
                <CustomTextField
                  label={'Email address (optional)'}
                  type={'email'}
                  className={'sm:w-full'}
                  value={state.form?.email}
                  onChange={(e) => {
                    handleFormChange({ email: e.target.value });
                  }}
                />
              </PageModuleFormRow>
              <PageModuleFormRow
                description={
                  'Field to store additional notes or information about the link. Only visible to administrators.'
                }
              >
                <CustomTextField
                  label={'Notes (optional)'}
                  className={'sm:w-full'}
                  value={state.form?.notes}
                  multiline
                  rows={5}
                  onChange={(e) => {
                    handleFormChange({ notes: e.target.value });
                  }}
                />
              </PageModuleFormRow>
              {/* <PageModuleFormRow description={'Optional message which can be displayed at the start of the factsheet as a banner, including in printout.'}> */}
              {/*   <CustomTextField label={'User message (optional)'} className={'sm:w-full'} value={state.form?.user_message} multiline rows={5} */}
              {/*                    onChange={(e) => {}}/> */}
              {/* </PageModuleFormRow> */}
              {state.putState?.strError && (
                <PageModuleFormRow>
                  <Alert severity={'error'}>{state.putState?.strError}</Alert>
                </PageModuleFormRow>
              )}
              <PageModuleFormRow>
                <ButtonXd
                  state={state.putState}
                  disabled={JSON.stringify(state.form) === JSON.stringify(state.adminFactsheetDetail)}
                  onClick={handleSaveChanges}
                >
                  Save changes
                </ButtonXd>
              </PageModuleFormRow>
            </PageModuleFormGroup>
          </PageModule>
          <PageModule background={<div className={'bg-red-50'} />}>
            <PageModuleFormGroup>
              <PageModuleTitle level={'h2'}>Delete factsheet link</PageModuleTitle>
              {state.deleteState?.strError && (
                <PageModuleFormRow>
                  <Alert severity={'error'}>{state.deleteState?.strError}</Alert>
                </PageModuleFormRow>
              )}
              <PageModuleFormRow
                description={
                  <>
                    <b>Important:</b> All viewing history will be removed when you delete this link, you might want to
                    consider disabling the link instead.
                  </>
                }
              >
                <ButtonXd icon={<DeleteForever />} color={'error'} state={state.deleteState} onClick={handleDelete}>
                  Delete
                </ButtonXd>
              </PageModuleFormRow>
            </PageModuleFormGroup>
          </PageModule>
        </>
      )}
    </Page>
  );
}
